export default {
    topic10: {
        ru: 'Мысли о программированиии',
        es: 'Pensamientos sobre programación',
        de: 'Gedanken zur Programmierung',
        en: 'Thoughts on programming',
        fr: `Pensées sur la programmation`
    },
    topic11: {
        ru: 'Изучение программирования — это как учиться ездить на велосипеде. Ты упадешь, но ключ в том, чтобы вставать и продолжать движение вперед.',
        es: 'Aprender a programar es como aprender a andar en bicicleta. Te caerás, pero lo importante es levantarte y seguir adelante.',
        de: 'Programmieren zu lernen ist wie Fahrradfahren lernen. Du wirst fallen, aber es ist entscheidend, aufzustehen und weiterzumachen.',
        en: 'Learning programming is like learning to ride a bike. You will fall, but the key is to get up and keep moving forward.',
        fr: `Apprendre la programmation, c'est comme apprendre à faire du vélo. Vous tomberez, mais l'essentiel est de se relever et de continuer à avancer.`
    },
    topic20: {
        ru: 'Мысли о программированиии',
        es: 'Pensamientos sobre programación',
        de: 'Gedanken zur Programmierung',
        en: 'Thoughts on programming',
        fr: `Pensées sur la programmation`
    },
    topic21: {
        ru: 'Программирование не о том, чтобы написать как можно больше кода, а о том, чтобы написать эффективный код.',
        es: 'La programación no se trata de escribir la mayor cantidad de código, sino de escribir código eficiente.',
        de: 'Programmieren bedeutet nicht, so viel Code wie möglich zu schreiben, sondern effizienten Code zu schreiben.',
        en: 'Programming is not about writing as much code as possible, but about writing efficient code.',
        fr: `La programmation ne consiste pas à écrire le plus de code possible, mais à écrire un code efficace.`
    },
    topic30: {
        ru: 'Важное о кодинге',
        es: 'Lo esencial sobre la codificación',
        de: 'Das Wichtigste über das Programmieren',
        en: 'The essentials of coding',
        fr: `L'essentiel du codage`
    },
    topic31: {
        ru: 'Самое важное в программировании — это решение проблем, а не сам код.',
        es: 'Lo más importante en la programación es la resolución de problemas, no el código en sí.',
        de: 'Das Wichtigste in der Programmierung ist die Problemlösung, nicht der Code selbst.',
        en: 'The most important part of programming is problem solving, not the code itself.',
        fr: `La partie la plus importante de la programmation est la résolution de problèmes, pas le code lui-même.`
    },
    pending: {
        ru: 'Ожидает',
        es: 'Pendiente',
        de: 'Anstehend',
        en: 'Pending',
        fr: `En attente`
    },
    submitted: {
        ru: 'Подтвержден',
        es: 'Confirmado',
        de: 'Bestätigt',
        en: 'Confirmed',
        fr: `Confirmé`
    },
    started: {
        ru: 'Начался',
        es: 'Iniciado',
        de: 'Gestartet',
        en: 'Started',
        fr: `Commencé`
    },
    HR: {
        ru: 'ХР',
        es: 'RRHH',
        de: 'HR',
        en: 'HR',
        fr: `RH`
    },
    urgency: {
        ru: 'Срочность',
        es: 'Urgencia',
        de: 'Dringlichkeit',
        en: 'Urgency',
        fr: `Urgence`
    },
    norm: {
        ru: 'Обычная',
        es: 'Normal',
        de: 'Normal',
        en: 'Normal',
        fr: `Normal`
    },
    tg: {
        ru: 'Телеграмм',
        es: 'Telegram',
        de: 'Telegram',
        en: 'Telegram',
        fr: `Telegram`
    },
    email: {
        ru: 'Эл. почта',
        es: 'Correo electrónico',
        de: 'E-Mail',
        en: 'Email',
        fr: `Email`
    },
    name1: {
        ru: 'Имя',
        es: 'Nombre',
        de: 'Vorname',
        en: 'First Name',
        fr: `Prénom`
    },
    name2: {
        ru: 'Фамилия',
        es: 'Apellido',
        de: 'Nachname',
        en: 'Last Name',
        fr: `Nom de famille`
    },
    name3: {
        ru: 'Отчество',
        es: 'Segundo nombre',
        de: 'Zweiter Vorname',
        en: 'Middle Name',
        fr: `Deuxième prénom`
    },
    yourLoginUsername: {
        ru: 'Ваш юзер нейм для логина',
        es: 'Tu nombre de usuario para iniciar sesión',
        de: 'Dein Benutzername zum Einloggen',
        en: 'Your Username for Login',
        fr: `Votre nom d'utilisateur pour la connexion`
    },
    urgent: {
        ru: 'Срочная',
        es: 'Urgente',
        de: 'Dringend',
        en: 'Urgent',
        fr: `Urgent`
    },
    answered: {
        ru: 'Отвечено',
        es: 'Respondido',
        de: 'Beantwortet',
        en: 'Answered',
        fr: `Répondu`
    },
    closed: {
        ru: 'Закрытая',
        es: 'Cerrado',
        de: 'Geschlossen',
        en: 'Closed',
        fr: `Fermé`
    },
    opened: {
        ru: 'Открытая',
        es: 'Abierto',
        de: 'Geöffnet',
        en: 'Opened',
        fr: `Ouvert`
    },
    open: {
        ru: 'Открытая',
        es: 'Abierto',
        de: 'Geöffnet',
        en: 'Open',
        fr: `Ouvert`
    },
    create: {
        ru: 'Создать',
        es: 'Crear',
        de: 'Erstellen',
        en: 'Create',
        fr: `Créer`
    },
    save: {
        ru: 'Сохранить',
        es: 'Guardar',
        de: 'Speichern',
        en: 'Save',
        fr: `Sauvegarder`
    },
    gph: {
        ru: 'ГПХ',
        es: 'Contrato por proyecto',
        de: 'Werkvertrag',
        en: 'Contract for Work',
        fr: `Contrat par projet`
    },
    cancel: {
        ru: 'Отмена',
        es: 'Cancelar',
        de: 'Abbrechen',
        en: 'Cancel',
        fr: `Annuler`
    },
    link: {
        ru: 'Ссылка',
        es: 'Enlace',
        de: 'Link',
        en: 'Link',
        fr: `Lien`
    },
    update: {
        ru: 'Обновить',
        es: 'Actualizar',
        de: 'Aktualisieren',
        en: 'Update',
        fr: `Mettre à jour`
    },
    tooGreatQuality: {
        ru: 'Слишком высокое качество файла',
        es: 'Calidad del archivo demasiado alta',
        de: 'Zu hohe Dateiqualität',
        en: 'File quality too high',
        fr: `Qualité du fichier trop élevée`
    },
    insertNewPass: {
        ru: 'Введите новый пароль',
        es: 'Introduce una nueva contraseña',
        de: 'Gib ein neues Passwort ein',
        en: 'Enter a new password',
        fr: `Entrez un nouveau mot de passe`
    },
    tooBig: {
        ru: 'Слишком большой размер файла. Разрешимый объем до 500мб',
        es: 'Tamaño del archivo demasiado grande. Capacidad permitida hasta 500 MB',
        de: 'Dateigröße zu groß. Erlaubtes Volumen bis zu 500 MB',
        en: 'File size too large. Allowed volume up to 500 MB',
        fr: `Taille du fichier trop grande. Volume autorisé jusqu'à 500 Mo`
    },
    errIncorrect: {
        ru: 'Некорректный или пустой видео файл',
        es: 'Archivo de vídeo incorrecto o vacío',
        de: 'Fehlerhafte oder leere Videodatei',
        en: 'Incorrect or empty video file',
        fr: `Fichier vidéo incorrect ou vide`
    },
    fileSize: {
        ru: 'Размер файла',
        es: 'Tamaño del archivo',
        de: 'Dateigröße',
        en: 'File Size',
        fr: `Taille du fichier`
    },
    duration: {
        ru: 'Продолжительность',
        es: 'Duración',
        de: 'Dauer',
        en: 'Duration',
        fr: `Durée`
    },
    tooGreat2: {
        ru: 'Для этой продолжительности видео макс размер может составлять:',
        es: 'Para esta duración de vídeo, el tamaño máximo puede ser:',
        de: 'Für diese Videolänge kann die maximale Größe betragen:',
        en: 'For this video length, the maximum size can be:',
        fr: `Pour cette durée de vidéo, la taille maximale peut être :`
    },
    tooGreat3: {
        ru: 'Текущий размер:',
        es: 'Tamaño actual:',
        de: 'Aktuelle Größe:',
        en: 'Current size:',
        fr: `Taille actuelle :`
    },
    minutesShort: {
        ru: 'мин',
        es: 'min',
        de: 'Min.',
        en: 'min',
        fr: `min`
    },
    theoryExplain: {
        ru: 'Теоретические пояснения',
        es: 'Explicaciones teóricas',
        de: 'Theoretische Erläuterungen',
        en: 'Theoretical explanations',
        fr: `Explications théoriques`
    },
    suggestOwn: {
        ru: 'Предложить свой ответ',
        es: 'Sugerir propia respuesta',
        de: 'Eigene Antwort vorschlagen',
        en: 'Suggest own answer',
        fr: `Suggérer sa propre réponse`
    },
    preview: {
        ru: 'Превью',
        es: 'Vista previa',
        de: 'Vorschau',
        en: 'Preview',
        fr: `Aperçu`
    },

    noCases: {
        ru: 'Нет кейсов для запуска - т.к. задача заключается в том чтобы укзаать логи в поле выше',
        es: 'No hay casos para lanzar - porque la tarea consiste en indicar los registros en el campo anterior',
        de: 'Keine Fälle zum Starten - da die Aufgabe darin besteht, die Logs im obigen Feld anzugeben',
        en: 'No cases to launch - as the task is to indicate logs in the field above',
        fr: `Pas de cas à lancer - la tâche consiste à indiquer les logs dans le champ ci-dessus`
    },
    expectResult: {
        ru: 'Ожидаемый результат',
        es: 'Resultado esperado',
        de: 'Erwartetes Ergebnis',
        en: 'Expected result',
        fr: `Résultat attendu`
    },
    currentResult: {
        ru: 'Текущий результат',
        es: 'Resultado actual',
        de: 'Aktuelles Ergebnis',
        en: 'Current Result',
        fr: `Résultat actuel`
    },
    addOrEditRunCases: {
        ru: 'Добавить или отредактировать запускные кейсы',
        es: 'Agregar o editar casos de ejecución',
        de: 'Ausführungsfälle hinzufügen oder bearbeiten',
        en: 'Add or Edit Run Cases',
        fr: `Ajouter ou modifier des cas d'exécution`
    },
    congratMsg: {
        ru: 'Поздравляем тест пройден',
        es: 'Felicidades, prueba superada',
        de: 'Glückwunsch, Test bestanden',
        en: 'Congratulations, test passed',
        fr: `Félicitations, test réussi`
    },
    editCases: {
        ru: 'Редактировать кейсы',
        es: 'Editar casos',
        de: 'Fälle bearbeiten',
        en: 'Edit Cases',
        fr: `Modifier les cas`
    },
    startRec: {
        ru: 'Начать запись',
        es: 'Comenzar grabación',
        de: 'Aufnahme starten',
        en: 'Start Recording',
        fr: `Commencer l'enregistrement`
    },
    explainMsg: {
        ru: 'Пояснения',
        es: 'Explicaciones',
        de: 'Erläuterungen',
        en: 'Explanations',
        fr: `Explications`
    },
    runMsg: {
        ru: 'Запуск',
        es: 'Ejecutar',
        de: 'Starten',
        en: 'Run',
        fr: `Exécuter`
    },
    submitMsg: {
        ru: 'Сабмит',
        es: 'Enviar',
        de: 'Einreichen',
        en: 'Submit',
        fr: `Soumettre`
    },
    solutionMsg: {
        ru: 'Решения',
        es: 'Soluciones',
        de: 'Lösungen',
        en: 'Solutions',
        fr: `Solutions`
    },
    testPasses: {
        ru: 'Пройдено тестов',
        es: 'Pruebas superadas',
        de: 'Tests bestanden',
        en: 'Tests Passed',
        fr: `Tests réussis`
    },
    reloadLogs: {
        ru: 'Перезапустить логи',
        es: 'Reiniciar registros',
        de: 'Logs neu laden',
        en: 'Reload Logs',
        fr: `Recharger les logs`
    },
    from: {
        ru: 'из',
        es: 'de',
        de: 'von',
        en: 'from',
        fr: `de`
    },
    startRate: {
        ru: 'Старт оценка',
        es: 'Calificación inicial',
        de: 'Startbewertung',
        en: 'Start Rating',
        fr: `Évaluation initiale`
    },
    curatorRate: {
        ru: 'Оценка куратора',
        es: 'Calificación del curador',
        de: 'Kuratorbewertung',
        en: 'Curator Rating',
        fr: `Évaluation du curateur`
    },
    reviewRate: {
        ru: 'Ревью',
        es: 'Revisión',
        de: 'Überprüfung',
        en: 'Review',
        fr: `Révision`
    },
    resetToDefault: {
        ru: 'Восстановить оригинальные значения',
        es: 'Restablecer a los valores predeterminados',
        de: 'Auf Standardwerte zurücksetzen',
        en: 'Reset to Default',
        fr: `Réinitialiser aux valeurs par défaut`
    },
    errorInTestCase: {
        ru: 'Ошибка в тест кейсе',
        es: 'Error en el caso de prueba',
        de: 'Fehler im Testfall',
        en: 'Error in Test Case',
        fr: `Erreur dans le cas de test`
    },
    programErrMsg: {
        ru: 'Ошибка выполнения программы',
        es: 'Error de ejecución del programa',
        de: 'Programmausführungsfehler',
        en: 'Program Execution Error',
        fr: `Erreur d'exécution du programme`
    },
    logsResponseMsg: {
        ru: 'Ответ логов',
        es: 'Respuesta de registros',
        de: 'Log-Antwort',
        en: 'Logs Response',
        fr: `Réponse des logs`
    },
    notStartedTests: {
        ru: 'Тесты еще не запускались',
        es: 'Pruebas no iniciadas',
        de: 'Tests noch nicht gestartet',
        en: 'Tests Not Started',
        fr: `Tests non démarrés`
    },
    resetCode: {
        ru: 'Резет код в оригинал',
        es: 'Restablecer código a original',
        de: 'Code auf Original zurücksetzen',
        en: 'Reset Code to Original',
        fr: `Réinitialiser le code à l'original`
    },
    runResults: {
        ru: 'Результаты запуска',
        es: 'Resultados de la ejecución',
        de: 'Ausführungsergebnisse',
        en: 'Run Results',
        fr: `Résultats de l'exécution`
    },
    case: {
        ru: 'Кейс',
        es: 'Caso',
        de: 'Fall',
        en: 'Case',
        fr: `Cas`
    },
    conditionMsg: {
        ru: 'Условие',
        es: 'Condición',
        de: 'Bedingung',
        en: 'Condition',
        fr: `Condition`
    },
    runResultsSubmit: {
        ru: 'Результаты запуска Сабмит',
        es: 'Enviar resultados de la ejecución',
        de: 'Ausführungsergebnisse einreichen',
        en: 'Submit Run Results',
        fr: `Soumettre les résultats de l'exécution`
    },
    results: {
        ru: 'Результаты',
        es: 'Resultados',
        de: 'Ergebnisse',
        en: 'Results',
        fr: `Résultats`
    },
    logs: {
        ru: 'Логи',
        es: 'Registros',
        de: 'Logs',
        en: 'Logs',
        fr: `Logs`
    },
    recognizing: {
        ru: 'Распознавание',
        es: 'Reconocimiento',
        de: 'Erkennung',
        en: 'Recognizing',
        fr: `Reconnaissance`
    },
    rateYourAnswer: {
        ru: 'Оцените ваш аудио ответ',
        es: 'Evalúa tu respuesta de audio',
        de: 'Bewerten Sie Ihre Audio-Antwort',
        en: 'Rate Your Audio Answer',
        fr: `Évaluez votre réponse audio`
    },

    rateYourCode: {
        ru: 'Оцените ваш код',
        es: 'Evalúa tu código',
        de: 'Bewerten Sie Ihren Code',
        en: 'Rate Your Code',
        fr: `Évaluez votre code`
    },
    mb: {
        ru: 'Мб',
        es: 'MB',
        de: 'MB',
        en: 'MB',
        fr: `Mo`
    },
    videoUploading: {
        ru: 'Загрузите видео',
        es: 'Cargar vídeo',
        de: 'Video hochladen',
        en: 'Upload Video',
        fr: `Télécharger la vidéo`
    },
    curatorConsultation: {
        ru: 'На данный момент обратитесь пожалуйста к куратору или техлиду за консультацией',
        es: 'Por favor consulta al curador o líder técnico en este momento',
        de: 'Bitte konsultieren Sie jetzt den Kurator oder den technischen Leiter',
        en: 'Please consult the curator or tech lead at this moment',
        fr: `Veuillez consulter le curateur ou le responsable technique en ce moment`
    },
    instructionSoon: {
        ru: 'Интсрукция по компрессии видео перед загрузкой в ближайшее время появится здесь!',
        es: '¡Las instrucciones para la compresión de video antes de cargar aparecerán aquí pronto!',
        de: 'Anleitung zur Videokompression vor dem Hochladen erscheint in Kürze hier!',
        en: 'Instructions for video compression before uploading will appear here soon!',
        fr: `Les instructions pour la compression vidéo avant le téléchargement apparaîtront ici bientôt!`
    },
    commentAnnotation: {
        ru: 'Комментарий-аннотация к видео',
        es: 'Comentario-anotación al vídeo',
        de: 'Kommentar-Annotation zum Video',
        en: 'Comment Annotation to Video',
        fr: `Annotation de commentaire à la vidéo`
    },
    selectFileFirst: {
        ru: 'Выберите в начале файл',
        es: 'Selecciona primero el archivo',
        de: 'Wählen Sie zuerst die Datei aus',
        en: 'Select File First',
        fr: `Sélectionnez d'abord le fichier`
    },
    selectFile: {
        ru: 'Выберите файл',
        es: 'Seleccionar archivo',
        de: 'Datei auswählen',
        en: 'Select File',
        fr: `Sélectionner un fichier`
    },
    errSearch2symbols: {
        ru: 'Поиск по вопросам в модулях доступен при 2-х и более символах',
        es: 'La búsqueda por preguntas en módulos está disponible con 2 o más caracteres',
        de: 'Die Suche nach Fragen in Modulen ist ab 2 oder mehr Zeichen verfügbar',
        en: 'Search by questions in modules is available with 2 or more characters',
        fr: `La recherche par questions dans les modules est disponible avec 2 caractères ou plus`
    },
    searchByQuestions: {
        ru: 'Поиск по вопросам',
        es: 'Buscar por preguntas',
        de: 'Nach Fragen suchen',
        en: 'Search by Questions',
        fr: `Recherche par questions`
    },
    emptyNotFoundRequest: {
        ru: 'Ничего не найдено по данному запросу',
        es: 'No se encontró nada para esta solicitud',
        de: 'Für diese Anfrage wurde nichts gefunden',
        en: 'Nothing found for this request',
        fr: `Rien trouvé pour cette demande`
    },
    team: {
        ru: "Команда",
        es: "Equipo",
        de: "Team",
        en: "Team",
        fr: `Équipe`
    },
    head: {
        ru: "Хэд",
        es: "Jefe",
        de: "Leiter",
        en: "Head",
        fr: `Responsable`
    },
    office: {
        ru: "Офис",
        es: "Oficina",
        de: "Büro",
        en: "Office",
        fr: `Bureau`
    },


    real: {
        ru: "Реальный",
        es: "Real",
        de: "Real",
        en: "Real",
        fr: `Réel`
    },
    ruLng: {
        ru: "Русский",
        es: "Ruso",
        de: "Russisch",
        en: "Russian",
        fr: `Russe`
    },
    enLng: {
        ru: "Английский",
        es: "Inglés",
        de: "Englisch",
        en: "English",
        fr: `Anglais`
    },
    frLng: {
        ru: "Французский",
        es: "Francés",
        de: "Französisch",
        en: "French",
        fr: `Français`
    },
    esLng: {
        ru: "Испанский",
        es: "Español",
        de: "Spanisch",
        en: "Spanish",
        fr: `Espagnol`
    },
    deLng: {
        ru: "Немецкий",
        es: "Alemán",
        de: "Deutsch",
        en: "German",
        fr: `Allemand`
    },
    virtual: {
        ru: "Виртуальный",
        es: "Virtual",
        de: "Virtuell",
        en: "Virtual",
        fr: `Virtuel`
    },
    isAllteams: {
        ru: "Все команды?",
        es: "¿Todos los equipos?",
        de: "Alle Teams?",
        en: "All teams?",
        fr: `Toutes les équipes ?`
    },
    shortTitle: {
        ru: "Короткое Название",
        es: "Título corto",
        de: "Kurztitel",
        en: "Short Title",
        fr: `Titre court`
    },
    editing: {
        ru: "Редактирую",
        es: "Editando",
        de: "Bearbeiten",
        en: "Editing",
        fr: `En train de modifier`
    },
    "----": {
        ru: "----",
        es: "----",
        de: "----",
        en: "----",
        fr: `----`
    },
    stop: {
        ru: "Стоп",
        es: "Parar",
        de: "Stopp",
        en: "Stop",
        fr: `Arrêt`
    },
    startTestRecord: {
        ru: 'Старт тестовой записи',
        es: 'Inicio de la grabación de prueba',
        de: 'Testaufnahme starten',
        en: 'Start test recording',
        fr: `Démarrage de l'enregistrement de test`
    },
    micRecMsg: {
        ru: 'Если у вас не получается настроить микрофон обратитесь к вашему куратору или тимлиду',
        es: 'Si no puedes configurar el micrófono, consulta a tu curador o líder de equipo',
        de: 'Wenn Sie das Mikrofon nicht einstellen können, wenden Sie sich an Ihren Kurator oder Teamleiter',
        en: 'If you are unable to set up the microphone, please consult your curator or team leader',
        fr: `Si vous ne parvenez pas à configurer le microphone, veuillez consulter votre conservateur ou chef d'équipe`
    },
    'isAll?': {
        ru: "Все команды?",
        es: "¿Todos los equipos?",
        de: "Alle Teams?",
        en: "All teams?",
        fr: `Toutes les équipes ?`
    },
    finRole: {
        ru: "Фин роль",
        es: "Rol financiero",
        de: "Finanzrolle",
        en: "Financial role",
        fr: `Rôle financier`
    },
    sale: {
        ru: "Продавец",
        es: "Vendedor",
        de: "Verkäufer",
        en: "Salesperson",
        fr: `Vendeur`
    },
    om: {
        ru: "Офис менеджер",
        es: "Gerente de oficina",
        de: "Büroleiter",
        en: "Office manager",
        fr: `Gestionnaire de bureau`
    },
    tl: {
        ru: "Тим лид",
        es: "Líder de equipo",
        de: "Teamleiter",
        en: "Team lead",
        fr: `Chef d'équipe`
    },
    account: {
        ru: "Аккаунт",
        es: "Cuenta",
        de: "Konto",
        en: "Account",
        fr: `Compte`
    },
    teamFrom: {
        ru: "Команда",
        es: "Equipo",
        de: "Team",
        en: "Team",
        fr: `Équipe`
    },
    first_name: {
        ru: "Имя",
        es: "Nombre",
        de: "Vorname",
        en: "First Name",
        fr: `Prénom`
    },
    name: {
        ru: "Название",
        es: "Nombre",
        de: "Name",
        en: "Name",
        fr: `Nom`
    },
    csore: {
        ru: "Апрувы",
        es: "Aprobaciones",
        de: "Genehmigungen",
        en: "Approvals",
        fr: `Approbations`
    },
    lock: {
        ru: "Занят",
        es: "Ocupado",
        de: "Beschäftigt",
        en: "Occupied",
        fr: `Occupé`
    },
    CScore: {
        ru: "Скоринг",
        es: "Puntuación",
        de: "Scoring",
        en: "Scoring",
        fr: `Score`
    },
    'lock?': {
        ru: "Занят?",
        es: "¿Ocupado?",
        de: "Beschäftigt?",
        en: "Occupied?",
        fr: `Occupé ?`
    },
    comment: {
        ru: "Коммент",
        es: "Comentario",
        de: "Kommentar",
        en: "Comment",
        fr: `Commentaire`
    },
    IntCount: {
        ru: "Кол-во",
        es: "Cantidad",
        de: "Anzahl",
        en: "Count",
        fr: `Nombre`
    },
    type: {
        ru: "Тип",
        es: "Tipo",
        de: "Typ",
        en: "Type",
        fr: `Type`
    },
    difficulty: {
        ru: "Сложность",
        es: "Dificultad",
        de: "Schwierigkeit",
        en: "Difficulty",
        fr: `Difficulté`
    },
    SuggestCount: {
        ru: "Кол-во предложений",
        es: "Cantidad de propuestas",
        de: "Anzahl der Vorschläge",
        en: "Number of proposals",
        fr: `Nombre de propositions`
    },
    Facts: {
        ru: "Кол-во фактов",
        es: "Cantidad de hechos",
        de: "Anzahl der Fakten",
        en: "Number of facts",
        fr: `Nombre de faits`
    },
    'UseCases / LC solutions': {
        ru: "Кол-во решений",
        es: "Cantidad de soluciones",
        de: "Anzahl der Lösungen",
        en: "Number of solutions",
        fr: `Nombre de solutions`
    },
    quizCount: {
        ru: "Кол-во квизов",
        es: "Cantidad de cuestionarios",
        de: "Anzahl der Quiz",
        en: "Number of quizzes",
        fr: `Nombre de quiz`
    },
    _id: {
        ru: "ИД",
        es: "ID",
        de: "ID",
        en: "ID",
        fr: `ID`
    },
    problemQuestions: {
        ru: "Проблемные вопросы",
        es: "Preguntas problemáticas",
        de: "Problematische Fragen",
        en: "Problematic Questions",
        fr: `Questions problématiques`
    },
    questions: {
        ru: "Вопросы",
        es: "Preguntas",
        de: "Fragen",
        en: "Questions",
        fr: `Questions`
    },
    question: {
        ru: "Вопрос",
        es: "Pregunta",
        de: "Frage",
        en: "Question",
        fr: `Question`
    },
    client: {
        ru: "Клиент",
        es: "Cliente",
        de: "Kunde",
        en: "Client",
        fr: `Client`
    },


    status: {
        ru: "Статус",
        es: "Estado",
        de: "Status",
        en: "Status",
        fr: `Statut`
    },
    date: {
        ru: "Дата",
        es: "Fecha",
        de: "Datum",
        en: "Date",
        fr: `Date`
    },
    cd: {
        ru: "Дата",
        es: "Fecha",
        de: "Datum",
        en: "Date",
        fr: `Date`
    },
    'js-task': {
        ru: "Код задача",
        es: "Tarea de código",
        de: "Code-Aufgabe",
        en: "Code task",
        fr: `Tâche de code`
    },
    'Sale manager': {
        ru: "Саилз",
        es: "Gerente de ventas",
        de: "Verkaufsleiter",
        en: "Sales Manager",
        fr: `Responsable des ventes`
    },
    Partner: {
        ru: "Партнер",
        es: "Socio",
        de: "Partner",
        en: "Partner",
        fr: `Partenaire`
    },
    'New HashTags': {
        ru: "Новые хэштеги",
        es: "Nuevos hashtags",
        de: "Neue Hashtags",
        en: "New hashtags",
        fr: `Nouveaux hashtags`
    },
    HashTags: {
        ru: "Хэштеги",
        es: "Hashtags",
        de: "Hashtags",
        en: "Hashtags",
        fr: `Hashtags`
    },
    'Interview Questions': {
        ru: "Вопросы интервью",
        es: "Preguntas de entrevista",
        de: "Interviewfragen",
        en: "Interview Questions",
        fr: `Questions d'entrevue`
    },
    ThemeQuestions: {
        ru: "Тематич. вопросы",
        es: "Preguntas temáticas",
        de: "Thematische Fragen",
        en: "Thematic Questions",
        fr: `Questions thématiques`
    },
    ThemeQuestion: {
        ru: "Тематич. вопрос",
        es: "Pregunta temática",
        de: "Thematische Frage",
        en: "Thematic Question",
        fr: `Question thématique`
    },
    Interviews: {
        ru: "Интервью",
        es: "Entrevistas",
        de: "Interviews",
        en: "Interviews",
        fr: `Entretiens`
    },
    InterviewQuestions: {
        ru: "Интервью вопросы",
        es: "Preguntas de entrevista",
        de: "Interviewfragen",
        en: "Interview Questions",
        fr: `Questions d'entrevue`
    },
    interviewQuestion: {
        ru: "Вопрос с интервью",
        es: "Pregunta de entrevista",
        de: "Interviewfrage",
        en: "Interview Question",
        fr: `Question d'entrevue`
    },
    Interview: {
        ru: "Интервью",
        es: "Entrevista",
        de: "Interview",
        en: "Interview",
        fr: `Entrevue`
    },
    '1d': {
        ru: "1 день",
        es: "1 día",
        de: "1 Tag",
        en: "1 day",
        fr: `1 jour`
    },
    '1w': {
        ru: "1 нед",
        es: "1 semana",
        de: "1 Woche",
        en: "1 week",
        fr: `1 semaine`
    },
    '2w': {
        ru: "2 нед",
        es: "2 semanas",
        de: "2 Wochen",
        en: "2 weeks",
        fr: `2 semaines`
    },
    '1m': {
        ru: "1 мес",
        es: "1 mes",
        de: "1 Monat",
        en: "1 month",
        fr: `1 mois`
    },
    Start: {
        ru: "Старт",
        es: "Inicio",
        de: "Start",
        en: "Start",
        fr: `Début`
    },
    End: {
        ru: "Конец",
        es: "Fin",
        de: "Ende",
        en: "End",
        fr: `Fin`
    },
    stat: {
        ru: "Статистика",
        es: "Estadística",
        de: "Statistik",
        en: "Statistics",
        fr: `Statistiques`
    },
    main: {
        ru: "Главная",
        es: "Principal",
        de: "Hauptseite",
        en: "Main",
        fr: `Principal`
    },
    courses: {
        ru: "Курсы",
        es: "Cursos",
        de: "Kurse",
        en: "Courses",
        fr: `Cours`
    },
    course: {
        ru: "Курс",
        es: "Curso",
        de: "Kurs",
        en: "Course",
        fr: `Cours`
    },
    progress: {
        ru: "Прогресс",
        es: "Progreso",
        de: "Fortschritt",
        en: "Progress",
        fr: `Progrès`
    },
    "Courses & Questions": {
        ru: "Курсы и вопросы",
        es: "Cursos y preguntas",
        de: "Kurse und Fragen",
        en: "Courses and Questions",
        fr: `Cours et questions`
    },
    UserId: {
        ru: "Пользователь",
        es: "ID de usuario",
        de: "Benutzer-ID",
        en: "User ID",
        fr: `ID utilisateur`
    },
    "Preview Page": {
        ru: "Превью",
        es: "Página de previsualización",
        de: "Vorschauseite",
        en: "Preview Page",
        fr: `Page de prévisualisation`
    },
    "Reset Stats": {
        ru: "Перезагрузка статистики",
        es: "Restablecer estadísticas",
        de: "Statistiken zurücksetzen",
        en: "Reset Stats",
        fr: `Réinitialiser les statistiques`
    },
    tag: {
        ru: "Тэг",
        es: "Etiqueta",
        de: "Tag",
        en: "Tag",
        fr: `Tag`
    },
    ParentName: {
        ru: "Отчество",
        es: "Segundo nombre",
        de: "Zweiter Vorname",
        en: "Middle Name",
        fr: `Deuxième prénom`
    },
    agent: {
        ru: "Подряд",
        es: "Contrato",
        de: "Vertrag",
        en: "Contract",
        fr: `Contrat`
    },
    full: {
        ru: "Полный",
        es: "Completo",
        de: "Vollständig",
        en: "Full",
        fr: `Complet`
    },
    final: {
        ru: "Финальный",
        es: "Final",
        de: "Final",
        en: "Final",
        fr: `Final`
    },
    fullPN: {
        ru: "Полный по период",
        es: "Completo por periodo",
        de: "Vollständig für den Zeitraum",
        en: "Full for the period",
        fr: `Complet pour la période`
    },
    monthlyPN: {
        ru: "Месячный по периоду",
        es: "Mensual por periodo",
        de: "Monatlich für den Zeitraum",
        en: "Monthly for the period",
        fr: `Mensuel pour la période`
    },
    finalPositive: {
        ru: "Итог приход",
        es: "Resultado positivo",
        de: "Positives Ergebnis",
        en: "Final income",
        fr: `Revenu final`
    },
    finalNegative: {
        ru: "Итог траты",
        es: "Resultado negativo",
        de: "Negatives Ergebnis",
        en: "Final expenses",
        fr: `Dépenses finales`
    },
    positive: {
        ru: "Приходы",
        es: "Ingresos",
        de: "Einnahmen",
        en: "Income",
        fr: `Revenus`
    },
    negative: {
        ru: "Траты",
        es: "Gastos",
        de: "Ausgaben",
        en: "Expenses",
        fr: `Dépenses`
    },
    surname: {
        ru: "Фамилия",
        es: "Apellido",
        de: "Nachname",
        en: "Surname",
        fr: `Nom de famille`
    },
    username: {
        ru: "Логин",
        es: "Nombre de usuario",
        de: "Benutzername",
        en: "Username",
        fr: `Nom d'utilisateur`
    },
    "userGrade": {
        ru: "Грейд",
        es: "Grado",
        de: "Grad",
        en: "Grade",
        fr: `Niveau`
    },
    "roles": {
        ru: "Роли",
        es: "Roles",
        de: "Rollen",
        en: "Roles",
        fr: `Rôles`
    },
    "approvedTags": {
        ru: "Аппрув Тэги",
        es: "Etiquetas aprobadas",
        de: "Genehmigte Tags",
        en: "Approved Tags",
        fr: `Tags approuvés`
    },
    "ignoreTags": {
        ru: "Игнор Тэги",
        es: "Ignorar etiquetas",
        de: "Tags ignorieren",
        en: "Ignore Tags",
        fr: `Ignorer les tags`
    },
    "isApprovedAll": {
        ru: "Все теги?",
        es: "¿Todos los tags?",
        de: "Alle Tags?",
        en: "All tags?",
        fr: `Tous les tags ?`
    },
    "active": {
        ru: "Актив",
        es: "Activo",
        de: "Aktiv",
        en: "Active",
        fr: `Actif`
    },
    "unactive": {
        ru: "Не актив",
        es: "Inactivo",
        de: "Inaktiv",
        en: "Inactive",
        fr: `Inactif`
    },
    "removed": {
        ru: "Удален",
        es: "Eliminado",
        de: "Entfernt",
        en: "Removed",
        fr: `Supprimé`
    },
    "plain_password": {
        ru: "Пароль",
        es: "Contraseña",
        de: "Passwort",
        en: "Password",
        fr: `Mot de passe`
    },
    "month": {
        ru: "Месяц сплит",
        es: "Mes dividido",
        de: "Monatssplit",
        en: "Month split",
        fr: `Division mensuelle`
    },
    "Password": {
        ru: "Пароль",
        es: "Contraseña",
        de: "Passwort",
        en: "Password",
        fr: `Mot de passe`
    },
    "active_search": {
        ru: "На поиске",
        es: "En búsqueda",
        de: "Suche aktiv",
        en: "Searching",
        fr: `En recherche`
    },
    "pause_search": {
        ru: "Пауза поиска",
        es: "Pausa en la búsqueda",
        de: "Suche pausieren",
        en: "Pause search",
        fr: `Pause de recherche`
    },
    "on_project": {
        ru: "На проекте",
        es: "En el proyecto",
        de: "Im Projekt",
        en: "On project",
        fr: `Sur le projet`
    },
    "endDate": {
        ru: "Дата конец",
        es: "Fecha de fin",
        de: "Enddatum",
        en: "End date",
        fr: `Date de fin`
    },
    "Get DebugLogs": {
        ru: "Получить дебаг логи",
        es: "Obtener registros de depuración",
        de: "Debug-Logs abrufen",
        en: "Get Debug Logs",
        fr: `Obtenir les logs de débogage`
    },
    "Generate": {
        ru: "Генерация",
        es: "Generar",
        de: "Generieren",
        en: "Generate",
        fr: `Générer`
    },
    "Regenerate User's History": {
        ru: "Пересобрать историю юзера",
        es: "Regenerar historial del usuario",
        de: "Benutzerverlauf neu erstellen",
        en: "Regenerate User's History",
        fr: `Régénérer l'historique de l'utilisateur`
    },
    "Clear CourseHistory to Zero": {
        ru: "Очистить историю",
        es: "Limpiar historial del curso a cero",
        de: "Kurshistorie auf Null setzen",
        en: "Clear Course History to Zero",
        fr: `Effacer l'historique des cours à zéro`
    },
    "Ignore Tags": {
        ru: "Игнор Тэги",
        es: "Ignorar etiquetas",
        de: "Tags ignorieren",
        en: "Ignore Tags",
        fr: `Ignorer les tags`
    },
    "Approve Tags": {
        ru: "Аппрув Тэги",
        es: "Aprobar etiquetas",
        de: "Tags genehmigen",
        en: "Approve Tags",
        fr: `Approuver les tags`
    },
    "Approve All Tags?": {
        ru: "Аппрув всех тэгов",
        es: "¿Aprobar todos los tags?",
        de: "Alle Tags genehmigen?",
        en: "Approve All Tags?",
        fr: `Approuver tous les tags ?`
    },
    "Removed / Finished Courses": {
        ru: "Удаленные или закончившиеся курсы",
        es: "Cursos eliminados o terminados",
        de: "Entfernte oder beendete Kurse",
        en: "Removed or Finished Courses",
        fr: `Cours supprimés ou terminés`
    },
    "+ Add item": {
        ru: "+ Запись",
        es: "+ Añadir elemento",
        de: "+ Element hinzufügen",
        en: "+ Add item",
        fr: `+ Ajouter un élément`
    },
    "isCV?": {
        ru: "Карьерный трек?",
        es: "¿Carrera profesional?",
        de: "Karriereweg?",
        en: "Career track?",
        fr: `Parcours professionnel ?`
    },
    "resetPass": {
        ru: "Обновить пароль",
        es: "Restablecer contraseña",
        de: "Passwort zurücksetzen",
        en: "Reset Password",
        fr: `Réinitialiser le mot de passe`
    },
    "User": {
        ru: "Пользователь",
        es: "Usuario",
        de: "Benutzer",
        en: "User",
        fr: `Utilisateur`
    },
    "quizExamSize": {
        ru: "Кол-во квизов в экзамене",
        es: "Número de cuestionarios en el examen",
        de: "Anzahl der Quiz im Examen",
        en: "Number of quizzes in exam",
        fr: `Nombre de quiz dans l'examen`
    },
    "examSize": {
        ru: "Размер экзамена",
        es: "Tamaño del examen",
        de: "Prüfungsgröße",
        en: "Exam size",
        fr: `Taille de l'examen`
    },
    "Questions To Find": {
        ru: "Воопрос на поиск",
        es: "Preguntas para encontrar",
        de: "Fragen zu finden",
        en: "Questions to find",
        fr: `Questions à trouver`
    },
    "Questions List": {
        ru: "Список вопросов",
        es: "Lista de preguntas",
        de: "Fragenliste",
        en: "Questions List",
        fr: `Liste des questions`
    },
    "Tags Questions List": {
        ru: "Вопросы по тегу",
        es: "Lista de preguntas por etiqueta",
        de: "Fragenliste nach Tags",
        en: "Tags Questions List",
        fr: `Liste des questions par tags`
    },
    "Add Bad Word": {
        ru: "Добавить игнор слова",
        es: "Agregar palabra prohibida",
        de: "Schlechtes Wort hinzufügen",
        en: "Add bad word",
        fr: `Ajouter un mot interdit`
    },
    "Favorite": {
        ru: "Избранное",
        es: "Favorito",
        de: "Favorit",
        en: "Favorite",
        fr: `Favori`
    },
    "NotFavorite": {
        ru: "Не Избранное",
        es: "No favorito",
        de: "Nicht Favorit",
        en: "Not favorite",
        fr: `Non favori`
    },
    "inviteId": {
        ru: "Инвайт",
        es: "Invitación",
        de: "Einladung",
        en: "Invite ID",
        fr: `ID d'invitation`
    },
    "Time": {
        ru: "Время",
        es: "Tiempo",
        de: "Zeit",
        en: "Time",
        fr: `Temps`
    },
    "Edit Module": {
        ru: "Редактирование модуля",
        es: "Editar módulo",
        de: "Modul bearbeiten",
        en: "Edit Module",
        fr: `Modifier le module`
    },
    "Preview Course Module Wrapper": {
        ru: "Превью курс модуля",
        es: "Vista previa del módulo del curso",
        de: "Vorschau des Kursmoduls",
        en: "Preview Course Module Wrapper",
        fr: `Aperçu de l'enveloppe du module de cours`
    },
    "setTQ": {
        ru: "Установить тематич. вопрос",
        es: "Establecer pregunta temática",
        de: "Thematische Frage setzen",
        en: "Set Thematic Question",
        fr: `Définir une question thématique`
    },
    "duplicate": {
        ru: "Дубликат",
        es: "Duplicado",
        de: "Duplikat",
        en: "Duplicate",
        fr: `Dupliquer`
    },
    "specialType": {
        ru: "Спец. тип",
        es: "Tipo especial",
        de: "Spezialtyp",
        en: "Special Type",
        fr: `Type spécial`
    },
    "isValid": {
        ru: "Валидировано?",
        es: "¿Validado?",
        de: "Validiert?",
        en: "Validated?",
        fr: `Validé ?`
    },
    "isFavorite": {
        ru: "Избранное?",
        es: "¿Favorito?",
        de: "Favorit?",
        en: "Favorite?",
        fr: `Favori ?`
    },
    "true": {
        ru: "Да",
        es: "Sí",
        de: "Ja",
        en: "Yes",
        fr: `Oui`
    },
    "false": {
        ru: "Нет",
        es: "No",
        de: "Nein",
        en: "No",
        fr: `Non`
    },
    "general": {
        ru: "Общий",
        es: "General",
        de: "Allgemein",
        en: "General",
        fr: `Général`
    },
    "audio": {
        ru: "Аудио",
        es: "Audio",
        de: "Audio",
        en: "Audio",
        fr: `Audio`
    },
    "quiz": {
        ru: "Квиз",
        es: "Cuestionario",
        de: "Quiz",
        en: "Quiz",
        fr: `Quiz`
    },
    "QUIZE": {
        ru: "Квиз",
        es: "Cuestionario",
        de: "Quiz",
        en: "Quiz",
        fr: `Quiz`
    },
    "QUIZES": {
        ru: "Квизы",
        es: "Cuestionarios",
        de: "Quizze",
        en: "Quizzes",
        fr: `Quizz`
    },
    "code": {
        ru: "Код",
        es: "Código",
        de: "Code",
        en: "Code",
        fr: `Code`
    },
    "Special Title": {
        ru: "Спец. название",
        es: "Título especial",
        de: "Spezialtitel",
        en: "Special Title",
        fr: `Titre spécial`
    },
    "Special Name": {
        ru: "Спец. имя",
        es: "Nombre especial",
        de: "Spezialname",
        en: "Special Name",
        fr: `Nom spécial`
    },
    "Special Type": {
        ru: "Спец. тип",
        es: "Tipo especial",
        de: "Spezialtyp",
        en: "Special Type",
        fr: `Type spécial`
    },
    "Special QType": {
        ru: "Спец. тип вопроса",
        es: "Tipo de pregunta especial",
        de: "Spezialfrage Typ",
        en: "Special Question Type",
        fr: `Type de question spécial`
    },
    "Assign Another Question": {
        ru: "Подключить другой вопрос",
        es: "Asignar otra pregunta",
        de: "Eine andere Frage zuweisen",
        en: "Assign Another Question",
        fr: `Attribuer une autre question`
    },
    "easy": {
        ru: "Легко",
        es: "Fácil",
        de: "Leicht",
        en: "Easy",
        fr: `Facile`
    },
    "medium": {
        ru: "Средне",
        es: "Medio",
        de: "Mittel",
        en: "Medium",
        fr: `Moyen`
    },
    "hard": {
        ru: "Сложно",
        es: "Difícil",
        de: "Schwierig",
        en: "Hard",
        fr: `Difficile`
    },
    "jun": {
        ru: "Джун",
        es: "Junior",
        de: "Junior",
        en: "Junior",
        fr: `Junior`
    },
    "mid": {
        ru: "Миддл",
        es: "Intermedio",
        de: "Mittel",
        en: "Mid-level",
        fr: `Intermédiaire`
    },
    "List OF QUSETIONS": {
        ru: "Список вопросов",
        es: "Lista de preguntas",
        de: "Fragenliste",
        en: "List of Questions",
        fr: `Liste des questions`
    },
    "senior": {
        ru: "Сеньор",
        es: "Senior",
        de: "Senior",
        en: "Senior",
        fr: `Senior`
    },
    "Select Questions": {
        ru: "Выберите вопросы",
        es: "Seleccionar preguntas",
        de: "Fragen auswählen",
        en: "Select Questions",
        fr: `Sélectionner des questions`
    },
    "Config Questions with Order": {
        ru: "Конфиг вопросов с сортировкой",
        es: "Configurar preguntas con orden",
        de: "Fragen mit Reihenfolge konfigurieren",
        en: "Config Questions with Order",
        fr: `Configurer les questions avec ordre`
    },
    "jsDetails": {
        ru: "Код детали",
        es: "Detalles de código",
        de: "Code-Details",
        en: "Code Details",
        fr: `Détails du code`
    },
    "Hints": {
        ru: "Подсказки",
        es: "Pistas",
        de: "Hinweise",
        en: "Hints",
        fr: `Indices`
    },
    "UseCases": {
        ru: "Практич. примеры",
        es: "Casos prácticos",
        de: "Anwendungsfälle",
        en: "Use Cases",
        fr: `Cas pratiques`
    },
    "videos": {
        ru: "Видео",
        es: "Vídeos",
        de: "Videos",
        en: "Videos",
        fr: `Vidéos`
    },
    "answer": {
        ru: "Ответ",
        es: "Respuesta",
        de: "Antwort",
        en: "Answer",
        fr: `Réponse`
    },
    "Admins": {
        ru: "Администраторы",
        es: "Administradores",
        de: "Administratoren",
        en: "Admins",
        fr: `Administrateurs`
    },
    "Hash": {
        ru: "Ключ доступа",
        es: "Clave de acceso",
        de: "Zugangsschlüssel",
        en: "Access Key",
        fr: `Clé d'accès`
    },
    "Currency": {
        ru: "Валюта",
        es: "Moneda",
        de: "Währung",
        en: "Currency",
        fr: `Devise`
    },
    "RUR": {
        ru: "₽",
        es: "₽",
        de: "₽",
        en: "₽",
        fr: `₽`
    },
    "EUR": {
        ru: "€",
        es: "€",
        de: "€",
        en: "€",
        fr: `€`
    },
    "USD": {
        ru: "$",
        es: "$",
        de: "$",
        en: "$",
        fr: `$`
    },
    "Teams": {
        ru: "Команды",
        es: "Equipos",
        de: "Teams",
        en: "Teams",
        fr: `Équipes`
    },
    "addVideo": {
        ru: "Добавьте видео",
        es: "Añadir vídeo",
        de: "Video hinzufügen",
        en: "Add Video",
        fr: `Ajouter une vidéo`
    },
    "cases": {
        ru: "ЮзКейсы",
        es: "Casos de uso",
        de: "Use-Cases",
        en: "Use Cases",
        fr: `Cas d'utilisation`
    },
    "addCase": {
        ru: "Добавьте юзкейс",
        es: "Añadir caso de uso",
        de: "Use-Case hinzufügen",
        en: "Add Use Case",
        fr: `Ajouter un cas d'utilisation`
    },
    "hints": {
        ru: "Подсказки",
        es: "Pistas",
        de: "Hinweise",
        en: "Hints",
        fr: `Indices`
    },
    "addHint": {
        ru: "Добавьте подсказу",
        es: "Añadir pista",
        de: "Hinweis hinzufügen",
        en: "Add Hint",
        fr: `Ajouter un indice`
    },
    "addFact": {
        ru: "Добавьте факт",
        es: "Añadir hecho",
        de: "Fakt hinzufügen",
        en: "Add Fact",
        fr: `Ajouter un fait`
    },
    "mainMain": {
        ru: "Основные",
        es: "Principal",
        de: "Haupt",
        en: "Main",
        fr: `Principal`
    },
    "interestingFacts": {
        ru: "Интересные факты",
        es: "Hechos interesantes",
        de: "Interessante Fakten",
        en: "Interesting Facts",
        fr: `Faits intéressants`
    },
    "emptyVideo": {
        ru: "Видео не добавлено",
        es: "Vídeo no añadido",
        de: "Kein Video hinzugefügt",
        en: "No video added",
        fr: `Aucune vidéo ajoutée`
    },
    "Tags": {
        ru: "Тэг",
        es: "Etiquetas",
        de: "Tags",
        en: "Tags",
        fr: `Tags`
    },
    "Title": {
        ru: "Название",
        es: "Título",
        de: "Titel",
        en: "Title",
        fr: `Titre`
    },
    "Question Type": {
        ru: "Тип вопроса",
        es: "Tipo de pregunta",
        de: "Fragetyp",
        en: "Question Type",
        fr: `Type de question`
    },
    "Timeout (to wait async)": {
        ru: "Таймаут ожидания",
        es: "Tiempo de espera (asíncrono)",
        de: "Timeout (für asynchrone Wartezeit)",
        en: "Timeout (to wait async)",
        fr: `Délai d'attente (asynchrone)`
    },
    "originalSolutionStatus": {
        ru: "Статус ориг решения",
        es: "Estado de la solución original",
        de: "Status der Original-Lösung",
        en: "Original Solution Status",
        fr: `Statut de la solution originale`
    },
    "hideRunStatus": {
        ru: "Скрыть статус",
        es: "Ocultar estado",
        de: "Laufstatus verbergen",
        en: "Hide Run Status",
        fr: `Masquer l'état d'exécution`
    },
    "CodeType": {
        ru: "Тип задачи",
        es: "Tipo de código",
        de: "Code-Typ",
        en: "Code Type",
        fr: `Type de code`
    },
    "WorkerType": {
        ru: "Тип занятости",
        es: "Tipo de empleo",
        de: "Beschäftigungstyp",
        en: "Employment Type",
        fr: `Type d'emploi`
    },
    "StartEducation": {
        ru: "Начало обучения",
        es: "Inicio de la educación",
        de: "Bildungsbeginn",
        en: "Start of Education",
        fr: `Début de l'éducation`
    },
    "StartWork": {
        ru: "Тип работы",
        es: "Inicio del trabajo",
        de: "Arbeitsbeginn",
        en: "Start of Work",
        fr: `Début du travail`
    },
    "DaysToPayMain": {
        ru: "Дней до Оклада",
        es: "Días hasta el salario",
        de: "Tage bis zur Bezahlung",
        en: "Days to Main Pay",
        fr: `Jours avant le paiement principal`
    },
    "DaysToPayAvance": {
        ru: "Дней до Аванса",
        es: "Días hasta el anticipo",
        de: "Tage bis zum Vorschuss",
        en: "Days to Advance Pay",
        fr: `Jours avant paiement anticipé`
    },
    "Info": {
        ru: "Информация",
        es: "Información",
        de: "Information",
        en: "Information",
        fr: `Information`
    },
    "Pull Letcode": {
        ru: "Подтянуть леткод",
        es: "Obtener código de Let",
        de: "Let-Code ziehen",
        en: "Pull Letcode",
        fr: `Tirer le code Let`
    },
    "sourceId": {
        ru: "Сорс ИД",
        es: "ID de fuente",
        de: "Quell-ID",
        en: "Source ID",
        fr: `ID de source`
    },

    "source": {
        ru: "Сорс",
        es: "Fuente",
        de: "Quelle",
        en: "Source",
        fr: `Source`
    },
    "isLTS": {
        ru: "ЛТС?",
        es: "¿Es LTS?",
        de: "Ist LTS?",
        en: "Is LTS?",
        fr: `Est LTS ?`
    },
    "VersionStr": {
        ru: "Версия",
        es: "Versión",
        de: "Version",
        en: "Version",
        fr: `Version`
    },
    "ForkVersion": {
        ru: "Форк Версия",
        es: "Versión bifurcada",
        de: "Fork-Version",
        en: "Fork Version",
        fr: `Version fork`
    },
    "suggests": {
        ru: "Предложения",
        es: "Sugerencias",
        de: "Vorschläge",
        en: "Suggestions",
        fr: `Suggestions`
    },
    "contributorsScore": {
        ru: "Оценка контрибьютеров",
        es: "Puntuación de colaboradores",
        de: "Bewertung der Beitragenden",
        en: "Contributors' Score",
        fr: `Score des contributeurs`
    },
    "contributors": {
        ru: "Контрибьютеры",
        es: "Colaboradores",
        de: "Mitwirkende",
        en: "Contributors",
        fr: `Contributeurs`
    },
    "refactor": {
        ru: "Рефактор",
        es: "Refactorización",
        de: "Refactoring",
        en: "Refactor",
        fr: `Refactorisation`
    },
    "problem": {
        ru: "Проблема",
        es: "Problema",
        de: "Problem",
        en: "Problem",
        fr: `Problème`
    },
    "discussion": {
        ru: "Обсуждение",
        es: "Discusión",
        de: "Diskussion",
        en: "Discussion",
        fr: `Discussion`
    },
    "perc": {
        ru: "%",
        es: "%",
        de: "%",
        en: "%",
        fr: `%`
    },
    "quizPerc": {
        ru: "% в квизах",
        es: "% en cuestionarios",
        de: "% in Quizzen",
        en: "% in quizzes",
        fr: `% dans les quiz`
    },
    "startCd": {
        ru: "Время начала",
        es: "Hora de inicio",
        de: "Startzeit",
        en: "Start Time",
        fr: `Heure de début`
    },
    "submitCd": {
        ru: "Время окончания",
        es: "Hora de finalización",
        de: "Endzeit",
        en: "End Time",
        fr: `Heure de fin`
    },
    "SourceName": {
        ru: "Название источника",
        es: "Nombre de la fuente",
        de: "Quellenname",
        en: "Source Name",
        fr: `Nom de la source`
    },
    "It's Current item": {
        ru: "Эте текущий элемент",
        es: "Es el elemento actual",
        de: "Es ist das aktuelle Element",
        en: "It's the current item",
        fr: `C'est l'élément actuel`
    },
    "Count": {
        ru: "Кол-во",
        es: "Cantidad",
        de: "Anzahl",
        en: "Count",
        fr: `Nombre`
    },
    "exam": {
        ru: "Экзамен",
        es: "Examen",
        de: "Prüfung",
        en: "Exam",
        fr: `Examen`
    },
    "module": {
        ru: "Модуль",
        es: "Módulo",
        de: "Modul",
        en: "Module",
        fr: `Module`
    },
    "tasks": {
        ru: "Задач",
        es: "Tareas",
        de: "Aufgaben",
        en: "Tasks",
        fr: `Tâches`
    },
    "total": {
        ru: "Всего",
        es: "Total",
        de: "Gesamt",
        en: "Total",
        fr: `Total`
    },
    "current": {
        ru: "Текущий",
        es: "Actual",
        de: "Aktuell",
        en: "Current",
        fr: `Actuel`
    },
    "Max Attempts To Submit": {
        ru: "Макс. кол-во сабмита",
        es: "Máximo de intentos para enviar",
        de: "Maximale Anzahl von Einreichungen",
        en: "Max Attempts to Submit",
        fr: `Nombre maximum de tentatives de soumission`
    },
    "Minutes": {
        ru: "Минут",
        es: "Minutos",
        de: "Minuten",
        en: "Minutes",
        fr: `Minutes`
    },
    "FULL EXAM DETAILS": {
        ru: "ДЕТАЛИ ЭКЗАМЕНА",
        es: "DETALLES COMPLETOS DEL EXAMEN",
        de: "VOLLE PRÜFUNGSDETAILS",
        en: "FULL EXAM DETAILS",
        fr: `DÉTAILS COMPLETS DE L'EXAMEN`
    },
    "Clone That": {
        ru: "Скопировать текущий",
        es: "Clonar eso",
        de: "Das klonen",
        en: "Clone That",
        fr: `Cloner cela`
    },
    "List": {
        ru: "Список",
        es: "Lista",
        de: "Liste",
        en: "List",
        fr: `Liste`
    },
    "Test Results": {
        ru: "Результаты экзамена",
        es: "Resultados del examen",
        de: "Prüfungsergebnisse",
        en: "Test Results",
        fr: `Résultats du test`
    },
    "RoR": {
        ru: "2-е ревью",
        es: "Segunda revisión",
        de: "Zweites Review",
        en: "Second Review",
        fr: `Deuxième révision`
    },
    "review": {
        ru: "Ревью",
        es: "Revisión",
        de: "Review",
        en: "Review",
        fr: `Révision`
    },
    "desc": {
        ru: "Описание",
        es: "Descripción",
        de: "Beschreibung",
        en: "Description",
        fr: `Description`
    },
    "sprintId": {
        ru: "Спринт ИД",
        es: "ID del sprint",
        de: "Sprint-ID",
        en: "Sprint ID",
        fr: `ID de sprint`
    },
    "courseId": {
        ru: "Курс ИД",
        es: "ID del curso",
        de: "Kurs-ID",
        en: "Course ID",
        fr: `ID de cours`
    },
    "examId": {
        ru: "Экзамен ИД",
        es: "ID del examen",
        de: "Prüfungs-ID",
        en: "Exam ID",
        fr: `ID d'examen`
    },
    "userId": {
        ru: "Пользователь ИД",
        es: "ID de usuario",
        de: "Benutzer-ID",
        en: "User ID",
        fr: `ID d'utilisateur`
    },
    "templateId": {
        ru: "Шаблон ИД",
        es: "ID de plantilla",
        de: "Vorlagen-ID",
        en: "Template ID",
        fr: `ID de modèle`
    },
    "Select All": {
        ru: "Выбрать Все",
        es: "Seleccionar todo",
        de: "Alles auswählen",
        en: "Select All",
        fr: `Sélectionner tout`
    },
    "Submit Full Test": {
        ru: "Сабмит весь экзамен",
        es: "Enviar todo el examen",
        de: "Ganzen Test einreichen",
        en: "Submit Full Test",
        fr: `Soumettre le test complet`
    },
    "LTS version": {
        ru: "ЛТС версия",
        es: "Versión LTS",
        de: "LTS-Version",
        en: "LTS Version",
        fr: `Version LTS`
    },
    "SUGGESTIONS": {
        ru: "Предложения контента",
        es: "Sugerencias de contenido",
        de: "Inhaltsvorschläge",
        en: "Content Suggestions",
        fr: `Suggestions de contenu`
    },
    "treeSearch": {
        ru: "Поиск по дереву",
        es: "Búsqueda en árbol",
        de: "Baumsuche",
        en: "Tree Search",
        fr: `Recherche arborescente`
    },
    "emptySelect": {
        ru: "Выберите элемент для просмотра",
        es: "Seleccione un elemento para visualizar",
        de: "Element zur Ansicht auswählen",
        en: "Select an item to view",
        fr: `Sélectionnez un élément à afficher`
    },
    "selectProject": {
        ru: "Выберите проект",
        es: "Seleccione proyecto",
        de: "Projekt auswählen",
        en: "Select Project",
        fr: `Sélectionner un projet`
    },
    "whatIsThis": {
        ru: "Что это",
        es: "¿Qué es esto?",
        de: "Was ist das?",
        en: "What is This?",
        fr: `Qu'est-ce que c'est ?`
    },
    "forWhat": {
        ru: "Для чего",
        es: "¿Para qué?",
        de: "Wofür?",
        en: "For What?",
        fr: `Pour quoi ?`
    },
    "avgTime": {
        ru: "Время разработки (примерное время - без мнеджмента)",
        es: "Tiempo promedio de desarrollo (tiempo estimado - sin gestión)",
        de: "Durchschnittliche Entwicklungszeit (geschätzte Zeit - ohne Management)",
        en: "Average Development Time (estimated time - without management)",
        fr: `Temps de développement moyen (temps estimé - sans gestion)`
    },
    "functional": {
        ru: "Функционал",
        es: "Funcionalidad",
        de: "Funktionalität",
        en: "Functional",
        fr: `Fonctionnel`
    },
    "potentialProblems": {
        ru: "Потенциальные проблемы",
        es: "Problemas potenciales",
        de: "Potenzielle Probleme",
        en: "Potential Problems",
        fr: `Problèmes potentiels`
    },
    "potentialSolution": {
        ru: "Потенциальные решение",
        es: "Solución potencial",
        de: "Potenzielle Lösung",
        en: "Potential Solution",
        fr: `Solution potentielle`
    },
    "esimationDetails": {
        ru: "Детали по оценке разных версий:",
        es: "Detalles de la estimación de diferentes versiones:",
        de: "Schätzdetails für verschiedene Versionen:",
        en: "Estimation Details for Different Versions:",
        fr: `Détails de l'estimation pour différentes versions :`
    },
    "potentialSteps": {
        ru: "Потенциальные Реализации",
        es: "Pasos potenciales",
        de: "Potenzielle Schritte",
        en: "Potential Steps",
        fr: `Étapes potentielles`
    },
    "isDublicate": {
        ru: "Это дубликат",
        es: "Es un duplicado",
        de: "Es ist ein Duplikat",
        en: "Is it a Duplicate?",
        fr: `Est-ce un doublon ?`
    },
    "min": {
        ru: "Минимальная",
        es: "Mínima",
        de: "Mindestens",
        en: "Minimum",
        fr: `Minimum`
    },
    "selectWhatImprove": {
        ru: "Выбери над чем стоит поработать в первую очередь",
        es: "Selecciona qué mejorar primero",
        de: "Wähle, was zuerst verbessert werden soll",
        en: "Select what to improve first",
        fr: `Sélectionnez ce qu'il faut améliorer en premier`
    },
    "selectVariant": {
        ru: "Выберите из списка",
        es: "Seleccione de la lista",
        de: "Wählen Sie aus der Liste",
        en: "Select from the list",
        fr: `Sélectionnez dans la liste`
    },
    "checkKnowledge": {
        ru: "Проверить знания",
        es: "Verificar conocimientos",
        de: "Wissen überprüfen",
        en: "Check knowledge",
        fr: `Vérifier les connaissances`
    },
    "like": {
        ru: "Нравится",
        es: "Me gusta",
        de: "Gefällt mir",
        en: "Like",
        fr: `Aimer`
    },
    "thanksFeedback": {
        ru: "Твой фидбек помогает всем обучаться быстрее, спасибо!",
        es: "Tu feedback ayuda a todos a aprender más rápido, ¡gracias!",
        de: "Dein Feedback hilft allen, schneller zu lernen, danke!",
        en: "Your feedback helps everyone learn faster, thank you!",
        fr: `Votre retour aide tout le monde à apprendre plus vite, merci !`
    },
    "dislike": {
        ru: "Не нравится",
        es: "No me gusta",
        de: "Gefällt mir nicht",
        en: "Dislike",
        fr: `Je n'aime pas`
    },
    "whatImprove": {
        ru: "Что улучшить?",
        es: "¿Qué mejorar?",
        de: "Was verbessern?",
        en: "What to improve?",
        fr: `Quoi améliorer ?`
    },
    "emptyLine": {
        ru: "Отсутствуют",
        es: "Vacío",
        de: "Leer",
        en: "Empty",
        fr: `Vide`
    },
    "breadCrumpVarients": {
        ru: "Варианты брендкрампа",
        es: "Variantes de migas de pan",
        de: "Breadcrumb-Varianten",
        en: "Breadcrumb variants",
        fr: `Variantes de fil d'Ariane`
    },
    "max": {
        ru: "Максимальная",
        es: "Máxima",
        de: "Maximal",
        en: "Maximum",
        fr: `Maximum`
    },
    "pluses": {
        ru: "Плюсы",
        es: "Ventajas",
        de: "Vorteile",
        en: "Pluses",
        fr: `Plus`
    },
    "completionPerc": {
        ru: "% изучения",
        es: "% de completado",
        de: "% abgeschlossen",
        en: "% Completion",
        fr: `% de complétion`
    },
    "trains": {
        ru: "Тренировка",
        es: "Entrenamiento",
        de: "Training",
        en: "Training",
        fr: `Formation`
    },
    "minuses": {
        ru: "Минусы",
        es: "Desventajas",
        de: "Nachteile",
        en: "Minuses",
        fr: `Moins`
    },
    "pages": {
        ru: "Страницы",
        es: "Páginas",
        de: "Seiten",
        en: "Pages",
        fr: `Pages`
    },
    "doubleRepeatInformation": {
        ru: "Закрепление информации",
        es: "Reforzar la información",
        de: "Informationen verstärken",
        en: "Reinforce information",
        fr: `Renforcer l'information`
    },
    "suggestCustomVariant": {
        ru: "Предложить свой вариант ответа",
        es: "Sugerir tu propia respuesta",
        de: "Eigene Antwort vorschlagen",
        en: "Suggest your own answer",
        fr: `Proposer votre propre réponse`
    },
    "send": {
        ru: "Отправить",
        es: "Enviar",
        de: "Senden",
        en: "Send",
        fr: `Envoyer`
    },
    "toRepeat": {
        ru: "На повторение",
        es: "Para repetir",
        de: "Zu wiederholen",
        en: "To repeat",
        fr: `À répéter`
    },
    "mockInterviews": {
        ru: "Мок-Интервью",
        es: "Entrevistas simuladas",
        de: "Mock-Interviews",
        en: "Mock Interviews",
        fr: `Entretiens simulés`
    },
    "stats": {
        ru: "Статистика",
        es: "Estadísticas",
        de: "Statistik",
        en: "Statistics",
        fr: `Statistiques`
    },
    "feedbacks": {
        ru: "Фидбеки",
        es: "Retroalimentaciones",
        de: "Feedbacks",
        en: "Feedbacks",
        fr: `Retours`
    },
    "startTrain": {
        ru: "Тренировать",
        es: "Entrenar",
        de: "Trainieren",
        en: "Train",
        fr: `Former`
    },
    "startTrainMore": {
        ru: "Тренировать еще",
        es: "Entrenar más",
        de: "Weiter trainieren",
        en: "Train more",
        fr: `Former davantage`
    },
    "feedMsg": {
        ru: "Здесь вы будете видеть ревью своей работы куратором",
        es: "Aquí verás la revisión de tu trabajo por el curador",
        de: "Hier siehst du die Überprüfung deiner Arbeit durch den Kurator",
        en: "Here you will see the review of your work by the curator",
        fr: `Ici, vous verrez la revue de votre travail par le curateur`
    },
    "percMatching": {
        ru: "% соответствия оценок",
        es: "% de coincidencia en las calificaciones",
        de: "% Übereinstimmung der Bewertungen",
        en: "% matching of ratings",
        fr: `% de correspondance des évaluations`
    },
    "feedsCount": {
        ru: "Кол-во не проработанных фидбеков:",
        es: "Cantidad de retroalimentaciones no procesadas:",
        de: "Anzahl nicht bearbeiteter Feedbacks:",
        en: "Number of unprocessed feedbacks:",
        fr: `Nombre de retours non traités :`
    },
    "feedGoodMsg": {
        ru: "Все фидбеки проработаны - ты молодец!",
        es: "Todos los feedbacks han sido procesados - ¡buen trabajo!",
        de: "Alle Feedbacks wurden bearbeitet - gut gemacht!",
        en: "All feedbacks have been processed - well done!",
        fr: `Tous les retours ont été traités - bien joué !`
    },
    "topic": {
        ru: "Топик",
        es: "Tema",
        de: "Thema",
        en: "Topic",
        fr: `Sujet`
    },
    "successCourse": {
        ru: "Молодец, ты закончил изучение курса",
        es: "Bien hecho, has completado el curso",
        de: "Gut gemacht, du hast den Kurs abgeschlossen",
        en: "Well done, you have completed the course",
        fr: `Bien joué, vous avez terminé le cours`
    },
    "noCheckTasks": {
        ru: "Нет задач для проверки в этой главе",
        es: "No hay tareas para verificar en este capítulo",
        de: "Keine Aufgaben zur Überprüfung in diesem Kapitel",
        en: "No tasks to check in this chapter",
        fr: `Pas de tâches à vérifier dans ce chapitre`
    },
    "completeAndReturn": {
        ru: "Завершить курс и вернуться в главное меню",
        es: "Completar el curso y volver al menú principal",
        de: "Kurs abschließen und zum Hauptmenü zurückkehren",
        en: "Complete the course and return to the main menu",
        fr: `Terminer le cours et revenir au menu principal`
    },
    "loadingResultsTesting": {
        ru: "Загружаем информацию о результатах тестирования",
        es: "Cargando información sobre los resultados de las pruebas",
        de: "Laden von Informationen über die Testergebnisse",
        en: "Loading information about testing results",
        fr: `Chargement des informations sur les résultats des tests`
    },
    "goodMsg1": {
        ru: "Уверен, ты действительно изучил эту тему хорошо",
        es: "Estoy seguro de que realmente has estudiado bien este tema",
        de: "Ich bin sicher, du hast dieses Thema wirklich gut gelernt",
        en: "I'm sure you have really studied this topic well",
        fr: `Je suis sûr que vous avez vraiment bien étudié ce sujet`
    },
    "goodMsg2": {
        ru: "Поэтому можешь переходить к следующему модулю",
        es: "Por lo tanto, puedes pasar al siguiente módulo",
        de: "Daher kannst du zum nächsten Modul übergehen",
        en: "Therefore, you can move on to the next module",
        fr: `Ainsi, vous pouvez passer au module suivant`
    },
    "yourPoints": {
        ru: "Вы набрали",
        es: "Has obtenido",
        de: "Du hast erreicht",
        en: "You scored",
        fr: `Vous avez obtenu`
    },
    "audioQ1": {
        ru: "Расскажите возможные алгоритмы решения, подводные камни, плюсы и минусы",
        es: "Describa los posibles algoritmos de solución, las trampas, ventajas y desventajas",
        de: "Beschreiben Sie mögliche Lösungsalgorithmen, Fallstricke, Vor- und Nachteile",
        en: "Describe possible solution algorithms, pitfalls, pros, and cons",
        fr: `Décrivez les algorithmes de solution possibles, les pièges, les avantages et les inconvénients`
    },
    "finishRec": {
        ru: "Завершить запись",
        es: "Finalizar grabación",
        de: "Aufnahme beenden",
        en: "Finish recording",
        fr: `Terminer l'enregistrement`
    },
    "availableLater": {
        ru: "Доступна после оценки",
        es: "Disponible después de la evaluación",
        de: "Verfügbar nach der Bewertung",
        en: "Available after assessment",
        fr: `Disponible après évaluation`
    },
    "repeatRec": {
        ru: "Перезаписать еще раз",
        es: "Regrabar otra vez",
        de: "Nochmals aufnehmen",
        en: "Re-record again",
        fr: `Réenregistrer`
    },
    "congratQuizMsg": {
        ru: "Поздравляем вы можете переходить к следующему блоку!",
        es: "¡Felicidades, puedes pasar a la siguiente unidad!",
        de: "Herzlichen Glückwunsch, du kannst zum nächsten Abschnitt übergehen!",
        en: "Congratulations, you can move on to the next section!",
        fr: `Félicitations, vous pouvez passer à la section suivante !`
    },
    "needMoreTimeQuizMsg": {
        ru: "Нужно еще немного поработать, тест не пройден!",
        es: "Necesitas trabajar un poco más, ¡la prueba no está superada!",
        de: "Du musst noch ein wenig arbeiten, der Test ist nicht bestanden!",
        en: "You need to work a bit more, the test is not passed!",
        fr: `Vous devez travailler un peu plus, le test n'est pas réussi !`
    },
    "quizResults": {
        ru: "Результаты Квиза",
        es: "Resultados del cuestionario",
        de: "Quiz-Ergebnisse",
        en: "Quiz Results",
        fr: `Résultats du quiz`
    },
    "startRepeat": {
        ru: "Начать повторение",
        es: "Comenzar la repetición",
        de: "Wiederholung starten",
        en: "Start repeating",
        fr: `Commencer la répétition`
    },
    "sort": {
        ru: "Сортировка",
        es: "Ordenar",
        de: "Sortieren",
        en: "Sort",
        fr: `Trier`
    },
    "trainPerc": {
        ru: "Тренировка %",
        es: "% de entrenamiento",
        de: "Trainingsprozentsatz",
        en: "Training %",
        fr: `% d'entraînement`
    },
    "examPerc": {
        ru: "Экзамен %",
        es: "% de examen",
        de: "Prüfungsprozentsatz",
        en: "Exam %",
        fr: `% d'examen`
    },
    "trainDate": {
        ru: "Тренировка дата",
        es: "Fecha de entrenamiento",
        de: "Trainingsdatum",
        en: "Training date",
        fr: `Date de formation`
    },
    "examDate": {
        ru: "Экзамен дата",
        es: "Fecha de examen",
        de: "Prüfungsdatum",
        en: "Exam date",
        fr: `Date d'examen`
    },
    "openNow": {
        ru: "Открыть сейчас",
        es: "Abrir ahora",
        de: "Jetzt öffnen",
        en: "Open now",
        fr: `Ouvrir maintenant`
    },
    "audioQ2": {
        ru: "Раскройте вопрос",
        es: "Desarrolla la pregunta",
        de: "Die Frage erläutern",
        en: "Elaborate the question",
        fr: `Développez la question`
    },
    "startExan": {
        ru: "Начать экзамен",
        es: "Iniciar examen",
        de: "Prüfung starten",
        en: "Start exam",
        fr: `Commencer l'examen`
    },
    "warnMsgNot5": {
        ru: "Попробуй еще раз, так как ответ не на 5.",
        es: "Intenta de nuevo, ya que la respuesta no es 5.",
        de: "Versuche es noch einmal, da die Antwort keine 5 ist.",
        en: "Try again, as the answer is not 5.",
        fr: `Réessayez, car la réponse n'est pas 5.`
    },
    "whyNot5": {
        ru: "Чего не хватило для 5?",
        es: "¿Qué faltó para un 5?",
        de: "Was fehlte zu einer 5?",
        en: "What was missing for a 5?",
        fr: `Qu'a-t-il manqué pour un 5 ?`
    },
    "micIniting": {
        ru: "Инициализация микрофона",
        es: "Inicialización del micrófono",
        de: "Mikrofon-Initialisierung",
        en: "Microphone initializing",
        fr: `Initialisation du microphone`
    },
    "autoRecognize": {
        ru: "Автоматическое распознавание текста",
        es: "Reconocimiento automático de texto",
        de: "Automatische Texterkennung",
        en: "Automatic text recognition",
        fr: `Reconnaissance automatique de texte`
    },
    "warnMsgNot5Available": {
        ru: "Кнопка станет доступна после попытки",
        es: "El botón estará disponible después del intento",
        de: "Der Knopf wird nach dem Versuch verfügbar sein",
        en: "The button will be available after the attempt",
        fr: `Le bouton sera disponible après la tentative`
    },
    "restartQuiz": {
        ru: "Перезапустить квиз",
        es: "Reiniciar cuestionario",
        de: "Quiz neu starten",
        en: "Restart quiz",
        fr: `Redémarrer le quiz`
    },
    "continueLearning": {
        ru: "Изучать материалы",
        es: "Continuar aprendiendo",
        de: "Mit dem Lernen fortfahren",
        en: "Continue learning",
        fr: `Continuer à apprendre`
    },
    "needPoints": {
        ru: "Необходимо набрать",
        es: "Necesitas obtener",
        de: "Punkte benötigt",
        en: "Need to score",
        fr: `Besoin de marquer`
    },
    "hiddenInfo": {
        ru: "Информации о доступных топиках не активна",
        es: "Información sobre temas disponibles no activa",
        de: "Informationen über verfügbare Themen nicht aktiv",
        en: "Information on available topics not active",
        fr: `Informations sur les sujets disponibles non actives`
    },
    "successModule": {
        ru: "Молодец, ты закончил изучение модуля",
        es: "Bien hecho, has completado el módulo",
        de: "Gut gemacht, du hast den Modul abgeschlossen",
        en: "Well done, you have completed the module",
        fr: `Bien joué, vous avez terminé le module`
    },
    "resultMsg": {
        ru: "Результат",
        es: "Resultado",
        de: "Ergebnis",
        en: "Result",
        fr: `Résultat`
    },
    "firstLearnMsg": {
        ru: "Впервые изучил вопросов",
        es: "Primera vez que se estudian preguntas",
        de: "Fragen zum ersten Mal gelernt",
        en: "First time questions learned",
        fr: `Questions apprises pour la première fois`
    },
    "studyQuestions": {
        ru: "Учил вопросов",
        es: "Estudió preguntas",
        de: "Fragen gelernt",
        en: "Studied questions",
        fr: `Questions étudiées`
    },
    "on5": {
        ru: "На отлично",
        es: "Excelente",
        de: "Ausgezeichnet",
        en: "Excellently",
        fr: `Excellent`
    },
    "on5inExam": {
        ru: "Сколько 5 в экзамене",
        es: "Cuántos 5 en el examen",
        de: "Wie viele 5 im Examen",
        en: "How many 5s in the exam",
        fr: `Combien de 5 à l'examen`
    },
    "on5inTrain": {
        ru: "Сколько 5 в тренировке",
        es: "Cuántos 5 en el entrenamiento",
        de: "Wie viele 5 im Training",
        en: "How many 5s in the training",
        fr: `Combien de 5 à l'entraînement`
    },
    "trainPassed": {
        ru: "Тренировок пройдено",
        es: "Entrenamientos completados",
        de: "Trainings absolviert",
        en: "Trainings completed",
        fr: `Formations complétées`
    },
    "avgRateTrain": {
        ru: "Средняя оценка тренировка",
        es: "Calificación media de entrenamiento",
        de: "Durchschnittliche Trainingsbewertung",
        en: "Average training rating",
        fr: `Note moyenne de formation`
    },
    "avgRateExam": {
        ru: "Средняя оценка экзамен",
        es: "Calificación media del examen",
        de: "Durchschnittliche Prüfungsbewertung",
        en: "Average exam rating",
        fr: `Note moyenne d'examen`
    },
    "recLenght": {
        ru: "Длина надиктовки",
        es: "Longitud de la grabación",
        de: "Länge der Diktierung",
        en: "Recording length",
        fr: `Longueur de l'enregistrement`
    },
    "recSpeed": {
        ru: "Скорость надиктовки",
        es: "Velocidad de la grabación",
        de: "Geschwindigkeit der Diktierung",
        en: "Recording speed",
        fr: `Vitesse d'enregistrement`
    },
    "prevChapter": {
        ru: "Предыдущая глава",
        es: "Capítulo anterior",
        de: "Vorheriges Kapitel",
        en: "Previous Chapter",
        fr: `Chapitre précédent`
    },
    "nextChapter": {
        ru: "Следующая глава",
        es: "Siguiente capítulo",
        de: "Nächstes Kapitel",
        en: "Next Chapter",
        fr: `Chapitre suivant`
    },
    "openNextMsg": {
        ru: "Модуль будет открыт после успешного прохождения предыдущих модулей",
        es: "El módulo se abrirá después de completar exitosamente los módulos anteriores",
        de: "Das Modul wird nach erfolgreichem Abschluss der vorherigen Module freigeschaltet",
        en: "The module will be opened after successfully completing previous modules",
        fr: `Le module sera ouvert après avoir réussi les modules précédents`
    },
    "runTaskInEditor": {
        ru: "Запустить задачу в редакторе",
        es: "Ejecutar tarea en el editor",
        de: "Aufgabe im Editor ausführen",
        en: "Run task in editor",
        fr: `Exécuter la tâche dans l'éditeur`
    },
    "startMockInterview": {
        ru: "Начать мок-интервью",
        es: "Iniciar entrevista simulada",
        de: "Mock-Interview starten",
        en: "Start mock interview",
        fr: `Démarrer un entretien simulé`
    },
    "(optional)": {
        ru: " (опционально)",
        es: " (opcional)",
        de: " (optional)",
        en: "(optional)",
        fr: ` (facultatif)`
    },
    "next": {
        ru: "Далее",
        es: "Siguiente",
        de: "Weiter",
        en: "Next",
        fr: `Suivant`
    },
    "availableTopics": {
        ru: "Доступные топики",
        es: "Temas disponibles",
        de: "Verfügbare Themen",
        en: "Available topics",
        fr: `Sujets disponibles`
    },
    "unclear_question": {
        ru: "Ответ не понятен",
        es: "Respuesta no clara",
        de: "Unklare Antwort",
        en: "Unclear answer",
        fr: `Réponse pas claire`
    },
    "not_deep_answer": {
        ru: "Не глубокий ответ",
        es: "Respuesta no profunda",
        de: "Oberflächliche Antwort",
        en: "Shallow answer",
        fr: `Réponse superficielle`
    },
    "not_actual_question": {
        ru: "Вопрос не актуален",
        es: "Pregunta no actual",
        de: "Frage ist nicht mehr aktuell",
        en: "Question not relevant",
        fr: `Question non pertinente`
    },
    "edit": {
        ru: "Редактирую",
        es: "Editando",
        de: "Bearbeiten",
        en: "Editing",
        fr: `En train de modifier`
    },
    "sent": {
        ru: "Отправлено",
        es: "Enviado",
        de: "Gesendet",
        en: "Sent",
        fr: `Envoyé`
    },
    "approved": {
        ru: "Проверено",
        es: "Aprobado",
        de: "Genehmigt",
        en: "Approved",
        fr: `Approuvé`
    },
    "canceled": {
        ru: "Отменено",
        es: "Cancelado",
        de: "Abgebrochen",
        en: "Canceled",
        fr: `Annulé`
    },
    "tech": {
        ru: "Тех",
        es: "Técnico",
        de: "Tech",
        en: "Tech",
        fr: `Tech`
    }, "waiting": {
        ru: "Ожидает",
        es: "Esperando",
        de: "Wartet",
        en: "Waiting",
        fr: `En attente`
    },
    "offer": {
        ru: "Офер",
        es: "Oferta",
        de: "Angebot",
        en: "Offer",
        fr: `Offre`
    },
    "next_stage": {
        ru: "След фаза",
        es: "Siguiente etapa",
        de: "Nächste Phase",
        en: "Next stage",
        fr: `Prochaine étape`
    },
    "bad": {
        ru: "Не прошли",
        es: "No aprobado",
        de: "Nicht bestanden",
        en: "Failed",
        fr: `Échoué`
    },
    "task": {
        ru: "Задача",
        es: "Tarea",
        de: "Aufgabe",
        en: "Task",
        fr: `Tâche`
    },
    "all": {
        ru: "Все",
        es: "Todo",
        de: "Alle",
        en: "All",
        fr: `Tout`
    },
    "empty": {
        ru: "Пусто",
        es: "Vacío",
        de: "Leer",
        en: "Empty",
        fr: `Vide`
    },
    "reject": {
        ru: "Отказ",
        es: "Rechazar",
        de: "Ablehnen",
        en: "Reject",
        fr: `Rejeter`
    },
    "inTime": {
        ru: "в",
        es: "en",
        de: "um",
        en: "at",
        fr: `à`
    },
    "footerLeft": {
        ru: "ИТК гос. регистрационный номер Программного Обеспечения №:2024685750",
        es: "Software de certificado de la Academia ITKEDU Nº: 2024685750",
        de: "ITKEDU-Akademie Zertifikat Software Nr..: 2024685750",
        en: "ITKEDU Academy Certificate software No.: 2024685750",
        fr: `Logiciel de certificat ITKEDU Academy N° : 2024685750`
    },
    "footerTitle1": {
        ru: "Создано с",
        es: "Creado con",
        de: "Erstellt mit",
        en: "Created with",
        fr: `Créé avec`
    },
    "searchModules": {
        ru: "Поиск по модулям",
        es: "Buscar en módulos",
        de: "Suche in Modulen",
        en: "Search in modules",
        fr: `Recherche dans les modules`
    },
    "noActiveNotifications": {
        ru: "Нет активных уведомлений!",
        es: "¡No hay notificaciones activas!",
        de: "Keine aktiven Benachrichtigungen!",
        en: "No active notifications!",
        fr: `Aucune notification active !`
    },
    "footerTitle2": {
        ru: "Итк Решения",
        es: "Soluciones ITK",
        de: "ITK Solutions",
        en: "ITK Solutions",
        fr: `Solutions ITK`
    },
    "page": {
        ru: "Страница",
        es: "Página",
        de: "Seite",
        en: "Page",
        fr: `Page`
    },
    "totalPages": {
        ru: "Всего страниц",
        es: "Total de páginas",
        de: "Gesamtseiten",
        en: "Total pages",
        fr: `Total des pages`
    },
    "totalCount": {
        ru: "Всего записей",
        es: "Total de entradas",
        de: "Gesamteinträge",
        en: "Total entries",
        fr: `Total des entrées`
    },
    "myExams": {
        ru: "Мои экзамены",
        es: "Mis exámenes",
        de: "Meine Prüfungen",
        en: "My exams",
        fr: `Mes examens`
    },
    "MyRequests": {
        ru: "Мои Заявки",
        es: "Mis solicitudes",
        de: "Meine Anfragen",
        en: "My requests",
        fr: `Mes demandes`
    },
    "addName": {
        ru: "Добавить",
        es: "Añadir",
        de: "Hinzufügen",
        en: "Add",
        fr: `Ajouter`
    },
    "search": {
        ru: "Поиск",
        es: "Buscar",
        de: "Suchen",
        en: "Search",
        fr: `Chercher`
    },
    "projectNotifications": {
        ru: "Проектные",
        es: "Notificaciones del proyecto",
        de: "Projektbenachrichtigungen",
        en: "Project notifications",
        fr: `Notifications de projet`
    },
    "developer": {
        ru: "Разработчик",
        es: "Desarrollador",
        de: "Entwickler",
        en: "Developer",
        fr: `Développeur`
    },
    "mainMenuShort": {
        ru: "Главное",
        es: "Principal",
        de: "Hauptmenü",
        en: "Main",
        fr: `Principal`
    },
    "mainMenu": {
        ru: "Главное Меню",
        es: "Menú principal",
        de: "Hauptmenü",
        en: "Main menu",
        fr: `Menu principal`
    },
    "nextQuizOpenAfter": {
        ru: "След квиз откроется автоматически через ",
        es: "El próximo cuestionario se abrirá automáticamente en ",
        de: "Das nächste Quiz öffnet sich automatisch nach ",
        en: "Next quiz will open automatically after ",
        fr: `Le prochain quiz s'ouvrira automatiquement après `
    },
    "secondsShort": {
        ru: "сек",
        es: "seg",
        de: "Sek",
        en: "sec",
        fr: `sec`
    },
    "extraMenu": {
        ru: "Дополнительное Меню",
        es: "Menú adicional",
        de: "Zusatzmenü",
        en: "Extra menu",
        fr: `Menu supplémentaire`
    },
    "requests": {
        ru: "Заявки",
        es: "Solicitudes",
        de: "Anfragen",
        en: "Requests",
        fr: `Demandes`
    },
    "interviews": {
        ru: "Интервью",
        es: "Entrevistas",
        de: "Interviews",
        en: "Interviews",
        fr: `Entretiens`
    },
    "carrier": {
        ru: "Карьерный трек",
        es: "Carrera profesional",
        de: "Karrierepfad",
        en: "Career track",
        fr: `Parcours professionnel`
    },
    "video": {
        ru: "Видео",
        es: "Video",
        de: "Video",
        en: "Video",
        fr: `Vidéo`
    },
    "micTest": {
        ru: "Тест Микрофона",
        es: "Prueba de micrófono",
        de: "Mikrofontest",
        en: "Mic test",
        fr: `Test de micro`
    },
    "suggest": {
        ru: "Предложения контента",
        es: "Sugerencias de contenido",
        de: "Inhaltsvorschläge",
        en: "Content suggestions",
        fr: `Suggestions de contenu`
    },
    "bannerTitle1": {
        ru: "Оффер за 3 месяца",
        es: "Oferta en 3 meses",
        de: "Angebot in 3 Monaten",
        en: "Offer in 3 months",
        fr: `Offre en 3 mois`
    },
    "bannerTitle2": {
        ru: "Быстрее всех на рынке обучения ИТ.",
        es: "El más rápido en el mercado educativo de IT.",
        de: "Der schnellste auf dem IT-Bildungsmarkt.",
        en: "Fastest in the IT education market.",
        fr: `Le plus rapide sur le marché de l'éducation IT.`
    },
    "bannerTitle3": {
        ru: "Почему это работает?",
        es: "¿Por qué funciona?",
        de: "Warum funktioniert das?",
        en: "Why does it work?",
        fr: `Pourquoi cela fonctionne-t-il ?`
    },
    "exit": {
        ru: "Выход",
        es: "Salir",
        de: "Ausgang",
        en: "Exit",
        fr: `Sortie`
    },
    "dashboard": {
        ru: "Дашборд",
        es: "Tablero",
        de: "Dashboard",
        en: "Dashboard",
        fr: `Tableau de bord`
    },
    "exams": {
        ru: "Экзамены",
        es: "Exámenes",
        de: "Prüfungen",
        en: "Exams",
        fr: `Examens`
    },
    "completeTime": {
        ru: "Время на выполнение",
        es: "Tiempo de completar",
        de: "Ausführungszeit",
        en: "Completion time",
        fr: `Temps d'achèvement`
    },
    "myInterviews": {
        ru: "Мои интервью",
        es: "Mis entrevistas",
        de: "Meine Interviews",
        en: "My interviews",
        fr: `Mes entretiens`
    },
    "mySuggestions": {
        ru: "Мои предложения контента",
        es: "Mis sugerencias de contenido",
        de: "Meine Inhaltsvorschläge",
        en: "My content suggestions",
        fr: `Mes suggestions de contenu`
    },
    "questionsSize": {
        ru: "Кол-во вопросов",
        es: "Número de preguntas",
        de: "Anzahl der Fragen",
        en: "Number of questions",
        fr: `Nombre de questions`
    },
    "back": {
        ru: "Вернуться",
        es: "Regresar",
        de: "Zurück",
        en: "Back",
        fr: `Retour`
    },
    "interviewPerc": {
        ru: "Процент заполненности интервью",
        es: "Porcentaje de entrevistas completadas",
        de: "Prozentsatz der abgeschlossenen Interviews",
        en: "Percentage of completed interviews",
        fr: `Pourcentage d'entretiens complétés`
    },
    "newQuestion": {
        ru: "Новый вопрос",
        es: "Nueva pregunta",
        de: "Neue Frage",
        en: "New question",
        fr: `Nouvelle question`
    },
    "toEnd": {
        ru: "до конца",
        es: "hasta el final",
        de: "bis zum Ende",
        en: "to the end",
        fr: `jusqu'à la fin`
    },
    "questionType": {
        ru: "Тип вопроса",
        es: "Tipo de pregunta",
        de: "Fragetyp",
        en: "Question type",
        fr: `Type de question`
    },
    "answerLevel": {
        ru: "Качество ответа",
        es: "Calidad de la respuesta",
        de: "Antwortqualität",
        en: "Answer quality",
        fr: `Qualité de la réponse`
    },
    "hashTags": {
        ru: "Хэш теги",
        es: "Hashtags",
        de: "Hashtags",
        en: "Hashtags",
        fr: `Hashtags`
    },
    "newTagCreation": {
        ru: "Создание нового тега",
        es: "Creación de una nueva etiqueta",
        de: "Erstellung eines neuen Tags",
        en: "Creation of a new tag",
        fr: `Création d'un nouveau tag`
    },
    "createTag": {
        ru: "Создать тег",
        es: "Crear etiqueta",
        de: "Tag erstellen",
        en: "Create tag",
        fr: `Créer un tag`
    },
    "chooseParent": {
        ru: "Выборите родителя",
        es: "Elegir un padre",
        de: "Eltern auswählen",
        en: "Choose a parent",
        fr: `Choisir un parent`
    },
    "insertAddQuestions": {
        ru: "Заданные уточняющие вопросы (укажите через Enter)",
        es: "Preguntas adicionales especificadas (indicar mediante Enter)",
        de: "Gestellte Klärungsfragen (bitte mit Enter eingeben)",
        en: "Specified additional questions (indicate via Enter)",
        fr: `Questions supplémentaires spécifiées (indiquer via Entrée)`
    },
    "top1mostInteresting": {
        ru: "ТОП-1 вопрос, самый интересный",
        es: "TOP-1 pregunta más interesante",
        de: "TOP-1 interessanteste Frage",
        en: "Top-1 most interesting question",
        fr: `Question la plus intéressante TOP-1`
    },
    "top3answers": {
        ru: "ТОП-3 лучших ответа",
        es: "TOP-3 mejores respuestas",
        de: "TOP-3 beste Antworten",
        en: "Top-3 best answers",
        fr: `Les 3 meilleures réponses`
    },
    "Overall assessment of the interview": {
        ru: "Общая оценка интервью",
        es: "Evaluación general de la entrevista",
        de: "Gesamtbewertung des Interviews",
        en: "Overall assessment of the interview",
        fr: `Évaluation générale de l'entretien`
    },
    "choosenParent": {
        ru: "Выборанный родитель",
        es: "Padre elegido",
        de: "Gewählter Elternteil",
        en: "Chosen parent",
        fr: `Parent choisi`
    },
    "minute": {
        ru: "Минуты",
        es: "Minutos",
        de: "Minuten",
        en: "Minutes",
        fr: `Minutes`
    },
    "seconds": {
        ru: "Секунды",
        es: "Segundos",
        de: "Sekunden",
        en: "Seconds",
        fr: `Secondes`
    },
    "analyse": {
        ru: "Анализ",
        es: "Análisis",
        de: "Analyse",
        en: "Analyse",
        fr: `Analyser`
    },
    "videoNotUploaded": {
        ru: "Видео не загружено",
        es: "Video no cargado",
        de: "Video nicht hochgeladen",
        en: "Video not uploaded",
        fr: `Vidéo non téléchargée`
    },
    "selectQuestion": {
        ru: "Выберите вопрос",
        es: "Seleccionar pregunta",
        de: "Frage auswählen",
        en: "Select question",
        fr: `Sélectionner la question`
    },
    "logout": {
        ru: "Выход",
        es: "Cerrar sesión",
        de: "Abmelden",
        en: "Logout",
        fr: `Déconnexion`
    },
    "videoLink": {
        ru: "Видео ссылка",
        es: "Enlace de video",
        de: "Videolink",
        en: "Video link",
        fr: `Lien vidéo`
    },
    "nextTopic": {
        ru: "Следующий топик",
        es: "Próximo tema",
        de: "Nächstes Thema",
        en: "Next topic",
        fr: `Prochain sujet`
    },
    "availableModules": {
        ru: "Доступные модули",
        es: "Módulos disponibles",
        de: "Verfügbare Module",
        en: "Available modules",
        fr: `Modules disponibles`
    },
    "profile": {
        ru: "Профиль",
        es: "Perfil",
        de: "Profil",
        en: "Profile",
        fr: `Profil`
    },
    "activeCourses": {
        ru: "Активные",
        es: "Activos",
        de: "Aktive",
        en: "Active",
        fr: `Actifs`
    },
    "archive": {
        ru: "Архив",
        es: "Archivo",
        de: "Archiv",
        en: "Archive",
        fr: `Archives`
    },
    "moduleCount": {
        ru: "Кол-во модулей",
        es: "Número de módulos",
        de: "Anzahl der Module",
        en: "Number of modules",
        fr: `Nombre de modules`
    },
    "eduPerc": {
        ru: "Процент изучения",
        es: "Porcentaje de estudio",
        de: "Lernprozentsatz",
        en: "Study percentage",
        fr: `Pourcentage d'étude`
    },
    "continue": {
        ru: "Продолжить",
        es: "Continuar",
        de: "Fortsetzen",
        en: "Continue",
        fr: `Continuer`
    },
    "examLoading": {
        ru: "Загрузка экзамена",
        es: "Cargando examen",
        de: "Prüfung wird geladen",
        en: "Loading exam",
        fr: `Chargement de l'examen`
    },
    "loading": {
        ru: "Загрузка",
        es: "Cargando",
        de: "Laden",
        en: "Loading",
        fr: `Chargement`
    },
    "noCorrectExamData": {
        ru: "Нет корректных данных об этом экзамене",
        es: "No hay datos correctos sobre este examen",
        de: "Keine korrekten Daten zu dieser Prüfung",
        en: "No correct data about this exam",
        fr: `Pas de données correctes sur cet examen`
    },
    "nothingFound": {
        ru: "Ничего не найдено",
        es: "Nada encontrado",
        de: "Nichts gefunden",
        en: "Nothing found",
        fr: `Rien trouvé`
    },
    "notifications": {
        ru: "Уведомления",
        es: "Notificaciones",
        de: "Benachrichtigungen",
        en: "Notifications",
        fr: `Notifications`
    },
    "needRate": {
        ru: "Нужно оценить",
        es: "Necesita ser evaluado",
        de: "Muss bewertet werden",
        en: "Needs to be rated",
        fr: `Doit être évalué`
    },
    "audioRate": {
        ru: "Оценка аудио",
        es: "Evaluación de audio",
        de: "Audio-Bewertung",
        en: "Audio rating",
        fr: `Évaluation audio`
    },
    "codeRate": {
        ru: "Оценка кода",
        es: "Evaluación de código",
        de: "Code-Bewertung",
        en: "Code rating",
        fr: `Évaluation du code`
    },
    "treeFunctional": {
        ru: "Дерево функционала",
        es: "Árbol funcional",
        de: "Funktionsbaum",
        en: "Functional tree",
        fr: `Arbre fonctionnel`
    },
    "taskNotStarted": {
        ru: "Задача не решалась",
        es: "Tarea no iniciada",
        de: "Aufgabe nicht begonnen",
        en: "Task not started",
        fr: `Tâche non commencée`
    },
    "offerMsg": {
        ru: "+ ИТК = Оффер",
        es: "+ ITK = Oferta",
        de: "+ ITK = Angebot",
        en: "+ ITK = Offer",
        fr: `+ ITK = Offre`
    },
    "projects": {
        ru: "Проекты",
        es: "Proyectos",
        de: "Projekte",
        en: "Projects",
        fr: `Projets`
    },
    "pageShort": {
        ru: "Стр",
        es: "Pág",
        de: "Seite",
        en: "Pg",
        fr: `Pg`
    },
    "contactEmailValue": {
        ru: "hello@itk.academy",
        es: "hello@itk.academy",
        de: "hello@itk.academy",
        en: "hello@itk.academy",
        fr: `hello@itk.academy`
    },
    "warnMsg3": {
        ru: "Мы каждый день усердно работаем чтобы ИТК Академия предоставляла лучшее качество услуг в РФ и мире",
        es: "Trabajamos duro todos los días para que la Academia ITK ofrezca la mejor calidad de servicio en Rusia y el mundo",
        de: "Wir arbeiten jeden Tag hart daran, dass die ITK-Akademie die beste Servicequalität in Russland und weltweit bietet",
        en: "We work hard every day to ensure ITK Academy provides the best quality of service in Russia and worldwide",
        fr: `Nous travaillons dur chaque jour pour que l'Académie ITK offre la meilleure qualité de service en Russie et dans le monde`
    },
    "warnMsg2": {
        ru: "hello@itk.academy",
        es: "hello@itk.academy",
        de: "hello@itk.academy",
        en: "hello@itk.academy",
        fr: `hello@itk.academy`
    },
    "warnMsg1": {
        ru: "Если менеджер плохо обработал заявку, то для отправки претензии к качеству используейте пожалуйста почту из публичной оферты",
        es: "Si un gerente ha procesado mal una solicitud, para enviar una queja sobre la calidad, utilice por favor el correo de la oferta pública",
        de: "Wenn ein Manager einen Antrag schlecht bearbeitet hat, verwenden Sie bitte die Mail aus dem öffentlichen Angebot, um eine Qualitätsbeschwerde zu senden",
        en: "If a manager has poorly processed a request, to send a quality complaint please use the mail from the public offer",
        fr: `Si un gestionnaire a mal traité une demande, pour envoyer une réclamation sur la qualité, veuillez utiliser le courriel de l'offre publique`
    },
    "onWork": {
        "ru": "На проработке",
        "es": "En proceso",
        "de": "In Bearbeitung",
        "en": "In progress",
        "fr": "En cours"
    },
    "newWork": {
        "ru": "Новый",
        "es": "Nuevo",
        "de": "Neu",
        "en": "New",
        "fr": "Nouveau"
    },
    "repeating": {
        "ru": "Повторный",
        "es": "Repetido",
        "de": "Wiederholend",
        "en": "Repeating",
        "fr": "Répétitif"
    },
    "whyItIsWorking": {
        "ru": "Почему это работает?",
        "es": "¿Por qué funciona esto?",
        "de": "Warum funktioniert das?",
        "en": "Why is it working?",
        "fr": "Pourquoi cela fonctionne-t-il?"
    },
    "eduAccreditationTitle": {
        "ru": "Мы знаем все про эффективное обучение",
        "es": "Sabemos todo sobre aprendizaje eficaz",
        "de": "Wir wissen alles über effektives Lernen",
        "en": "We know everything about effective learning",
        "fr": "Nous savons tout sur l'apprentissage efficace"
    },
    "eduAccreditationDesc": {
        "ru": "Мы 10 лет учим ИТ. Более того, мы получили все государственные лицензиии для занятия образовательной деятельностью. Государство готово зачитывать материнский капитал в компенсацию нашего обучения",
        "es": "Llevamos 10 años enseñando TI. Además, hemos obtenido todas las licencias estatales para dedicarnos a la actividad educativa. El estado está dispuesto a compensar nuestra formación con el capital materno.",
        "de": "Wir unterrichten seit 10 Jahren IT. Darüber hinaus haben wir alle staatlichen Lizenzen für die Ausübung der Bildungsaktivität erhalten. Der Staat ist bereit, unsere Ausbildung mit dem Mutterschaftskapital zu verrechnen.",
        "en": "We have been teaching IT for 10 years. Moreover, we have obtained all state licenses for educational activities. The state is ready to offset our training with the maternal capital.",
        "fr": "Nous enseignons les TI depuis 10 ans. En outre, nous avons obtenu toutes les licences d'État pour exercer des activités éducatives. L'État est prêt à compenser notre formation avec le capital maternel."
    },
    "portalTitle": {
        "ru": "Мы используем ИИ-технологии для обучения",
        "es": "Utilizamos tecnologías de IA para la enseñanza",
        "de": "Wir verwenden KI-Technologien für die Ausbildung",
        "en": "We use AI technologies for education",
        "fr": "Nous utilisons les technologies de l'IA pour l'éducation"
    },
    "portalDesc": {
        "ru": "На основе кривой забывания информации человеческим мозгом на нашем портале реализована система интервальных повторений Лейтнеца, система мультиканального обучения Э. Дейла - поэтому подготовка будет самой быстрой!",
        "es": "Basándonos en la curva de olvido de información del cerebro humano, en nuestro portal se ha implementado un sistema de repeticiones intervaladas de Leitner y un sistema de aprendizaje multicanal de E. Dale, ¡por lo que la preparación será la más rápida!",
        "de": "Basierend auf der Vergessenskurve von Informationen im menschlichen Gehirn wurde auf unserer Plattform ein System für intermittierende Wiederholungen nach Leitner und ein Multikanalsystem für E-Learning nach E. Dale umgesetzt, daher wird die Vorbereitung am schnellsten sein!",
        "en": "Based on the forgetting curve of information by the human brain, our portal has implemented a system of spaced repetitions by Leitner and a multi-channel learning system by E. Dale, so preparation will be the fastest!",
        "fr": "Sur la base de la courbe d'oubli des informations par le cerveau humain, notre portail a mis en œuvre un système de répétitions espacées de Leitner et un système d'apprentissage multicanal de E. Dale, donc la préparation sera la plus rapide !"
    },
    "curatorsTitle": {
        "ru": "Менторы и кураторы - практикующие техлиды и разработчики",
        "es": "Mentores y tutores - líderes técnicos y desarrolladores practicantes",
        "de": "Mentoren und Tutoren sind praktizierende Tech-Leads und Entwickler",
        "en": "Mentors and curators are practicing tech leads and developers",
        "fr": "Les mentors et tuteurs sont des leaders techniques et développeurs pratiquants"
    },
    "curatorsDesc": {
        "ru": "Наши менторы и кураторы - имеют практический опыт разработки и управления командами на ТОП проектах уровня Сбербанк, МТС-тех, Озон-тех и др.",
        "es": "Nuestros mentores y tutores tienen experiencia práctica en desarrollo y gestión de equipos en proyectos TOP como Sberbank, MTS-tech, Ozon-tech y otros.",
        "de": "Unsere Mentoren und Tutoren verfügen über praktische Erfahrung in der Entwicklung und Teamleitung bei TOP-Projekten wie Sberbank, MTS-Tech, Ozon-Tech und anderen.",
        "en": "Our mentors and curators have practical experience in development and team management at top projects like Sberbank, MTS-tech, Ozon-tech, etc.",
        "fr": "Nos mentors et tuteurs ont une expérience pratique dans le développement et la gestion d'équipes au sein de projets de premier plan comme Sberbank, MTS-tech, Ozon-tech, etc."
    },
    "hireTitle": {
        "ru": "Трудоустройство - наша сильная сторона",
        "es": "El empleo es nuestra fuerte",
        "de": "Die Beschäftigung ist unsere Stärke",
        "en": "Employment is our strong suit",
        "fr": "L'embauche est notre point fort"
    },
    "hireDesc": {
        "ru": "Знаем все о трудоустройстве! Мы используем ИИ для рассылки ваших персонализированных резюме по огромной базе клиентов и партнеров. Благодаря чему - наши студенты имеют очень большое количество собеседований!",
        "es": "¡Sabemos todo sobre el empleo! Utilizamos IA para enviar sus currículums personalizados a una gran base de clientes y socios. ¡Gracias a eso, nuestros estudiantes tienen una gran cantidad de entrevistas!",
        "de": "Wir wissen alles über die Vermittlung! Wir nutzen KI, um Ihre personalisierten Lebensläufe an eine große Datenbank von Kunden und Partnern zu versenden. Dank dessen haben unsere Studenten eine sehr große Anzahl an Vorstellungsgesprächen!",
        "en": "We know everything about employment! We use AI to send your personalized resumes to a large database of clients and partners. Thanks to this, our students have a very large number of interviews!",
        "fr": "Nous savons tout sur l'embauche ! Nous utilisons l'IA pour envoyer vos CV personnalisés à une grande base de clients et de partenaires. Grâce à cela, nos étudiants ont un très grand nombre d'entretiens !"
    },
    "confirmAction": {
        "ru": "Подтвердите пожалуйста действие",
        "es": "Confirmar acción por favor",
        "de": "Bitte bestätigen Sie die Aktion",
        "en": "Please confirm the action",
        "fr": "Veuillez confirmer l'action"
    },
    "yesLogout": {
        "ru": "Да, выйти",
        "es": "Sí, salir",
        "de": "Ja, ausloggen",
        "en": "Yes, log out",
        "fr": "Oui, se déconnecter"
    },
    "loginBtn": {
        "ru": "Вход",
        "es": "Iniciar sesión",
        "de": "Anmelden",
        "en": "Login",
        "fr": "Connexion"
    },
    "login": {
        "ru": "Логин",
        "es": "Inicio de sesión",
        "de": "Anmeldung",
        "en": "Login",
        "fr": "Connexion"
    },
    "loginAgree": {
        "ru": "Нажимая на кнопку, я соглашаюсь с",
        "es": "Al pulsar el botón, acepto la",
        "de": "Durch Anklicken des Buttons stimme ich zu der",
        "en": "By clicking the button, I agree with the",
        "fr": "En cliquant sur le bouton, j'accepte la"
    },
    "policy": {
        "ru": "Политикой конфиденциальности",
        "es": "Política de privacidad",
        "de": "Datenschutzrichtlinie",
        "en": "Privacy Policy",
        "fr": "Politique de confidentialité"
    },
    "areYouSureLogout": {
        "ru": "Вы уверены, что хотите выйти?",
        "es": "¿Está seguro de que desea salir?",
        "de": "Sind Sie sicher, dass Sie sich abmelden möchten?",
        "en": "Are you sure you want to log out?",
        "fr": "Êtes-vous sûr de vouloir vous déconnecter?"
    },
    "confirm": {
        "ru": "Да, подтвердить",
        "es": "Sí, confirmar",
        "de": "Ja, bestätigen",
        "en": "Yes, confirm",
        "fr": "Oui, confirmer"
    },
    "areYouSureToComplete": {
        "ru": "Вы уверены, что хотите закончить экзамен?",
        "es": "¿Está seguro de que desea finalizar el examen?",
        "de": "Sind Sie sicher, dass Sie die Prüfung beenden möchten?",
        "en": "Are you sure you want to complete the exam?",
        "fr": "Êtes-vous sûr de vouloir terminer l'examen?"
    },
    "completeExam": {
        "ru": "Завершить экзамен",
        "es": "Finalizar examen",
        "de": "Prüfung abschließen",
        "en": "Complete Exam",
        "fr": "Terminer l'examen"
    },
    "thanksForUnderstanding": {
        "ru": "Спасибо, за напоминание",
        "es": "Gracias por recordarnos",
        "de": "Danke für das Erinnern",
        "en": "Thanks for reminding",
        "fr": "Merci pour le rappel"
    },
    "notActiveExam": {
        "ru": "Экзамен не активен",
        "es": "El examen no está activo",
        "de": "Die Prüfung ist nicht aktiv",
        "en": "The exam is not active",
        "fr": "L'examen n'est pas actif"
    },
    "waitToStartExam": {
        "ru": "Дождитесь пока модератор сделает доступным этот экзамен",
        "es": "Espere a que el moderador haga este examen disponible",
        "de": "Warten Sie, bis der Moderator diese Prüfung verfügbar macht",
        "en": "Wait until the moderator makes this exam available",
        "fr": "Attendez que le modérateur rende cet examen disponible"
    },
    "youstillhave": {
        "ru": "У вас осталось",
        "es": "Te quedan",
        "de": "Sie haben noch",
        "en": "You still have",
        "fr": "Il vous reste"
    },
    "congratExamComplete": {
        "ru": "Поздравляем, вы прошли все тесты! Теперь можете приступить к задачам или завершить экзамен!",
        "es": "¡Felicidades, ha completado todas las pruebas! ¡Ahora puede comenzar con las tareas o finalizar el examen!",
        "de": "Glückwunsch, Sie haben alle Tests abgeschlossen! Jetzt können Sie mit den Aufgaben beginnen oder die Prüfung beenden!",
        "en": "Congratulations, you've completed all the tests! You can now start on tasks or complete the exam!",
        "fr": "Félicitations, vous avez passé tous les tests ! Vous pouvez maintenant commencer les tâches ou terminer l'examen !"
    },
    "rateYourPlainAnswer": {
        "ru": "Оцените ваш ответ",
        "es": "Califique su respuesta",
        "de": "Bewerten Sie Ihre Antwort",
        "en": "Rate your answer",
        "fr": "Évaluez votre réponse"
    },
    "dropFileToUpload": {ru: 'Перетащите файл для загрузки', en: 'Drop file here to upload'},
    "uploadAvatar": {ru: 'Загрузить аватар'},
    "resetToAvatarDefault": {ru: 'Установаить стандартный'},
    "responseTime": {
        "ru": "Время ответа",
        "es": "Tiempo de respuesta",
        "de": "Antwortzeit",
        "en": "Response time",
        "fr": "Temps de réponse"
    },
    "titleH1": {
        "ru": "Портал Развития",
        "en": "Education Portal",
        "es": "Portal de Educación",
        "de": "Bildungsportal",
        "fr": "Portail d'Éducation"
    },
    "areYouSure": {
        "ru": "Вы уверены, что хотите восстановить оригинальный код?",
        "es": "¿Está seguro de que desea restaurar el código original?",
        "de": "Sind Sie sicher, dass Sie den Originalcode wiederherstellen möchten?",
        "en": "Are you sure you want to restore the original code?",
        "fr": "Êtes-vous sûr de vouloir rétablir le code original?"
    },
    "loginErr": {
        "ru": "Неверный логин / пароль",
        "es": "Nombre de usuario o contraseña incorrectos",
        "de": "Falscher Benutzername oder Passwort",
        "en": "Incorrect username or password",
        "fr": "Nom d'utilisateur ou mot de passe incorrect"
    },

    "rate55": {ru: 'Оценка'},
    "rateAdmin51": {ru: 'Оценка'},
    "micProblemErr": {ru: 'Проблемы с подключением микрофона! Сделайте отладку на странице тестирования микрофона'},
    "": {ru: ''},

}

/*

Extend object with fields:  es (spain), de(germany), en(english), fr(Franch) to that object, franch place in  comma to keep JSON formatting (keep main key in commas "")
  loginErr: {
        ru: 'Неверный логин / пароль'
    },




 */